import React, { Component } from 'react';
import Navbar from '../../Views/Navbar/NavbarAdHome';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles, TextField, Typography, CssBaseline, Container, Button, Grid } from '@material-ui/core';
import { firebase } from '../../Firebase';
import Snackbar from './../../Components/Snackbar';
import Loader from '../../Components/Loader';
import PageHeading from './../../Views/PageHeading';
import VolunteerCard from './../../Components/VolunteerCard';
import axios from 'axios';

const styles = (theme) => ({
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submitButton: {
		marginTop: theme.spacing(1),
		float: 'right',
	},
	manageButton: {
		marginTop: theme.spacing(1),
	},
});

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editVolunteers: false,
			addVolunteers: false,
			admin: false,
			isLoading: true,
			isLogin: false,
			volunteerData: [],
			volunteerDataId: [],
			snackbarOpen: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			update: false,
			updateId: '',
			nameOfVolunteer: '',
			rankofVolunteer: '',
			areaOfVolunteer: '',
			phoneNumberOfVolunteer: '',
			emailOfVolunteer: '',
			numOfEventsOrganizedByVolunteer: '',
			modal: false,
			approveModal: false,
			userEmail: '',
			editEvents: false,
			addEvents: false,
			deleteDialogOpen: false,
			deleteId: '',
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		var editVolunteers, addVolunteers, admin;
		const volunteerManageComponent = this;

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({ userEmail: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('volunteers')
						.where('emailOfVolunteer', '==', this.state.userEmail)
						.get()
						.then(function (querySnapshot) {
							querySnapshot.forEach(function (doc) {
								editVolunteers = doc.data().permissions['editVolunteers'];
								addVolunteers = doc.data().permissions['addVolunteers'];
								admin = doc.data().permissions['admin'];
							});

							volunteerManageComponent.setState({
								editVolunteers: editVolunteers,
								addVolunteers: addVolunteers,
								admin: admin,
							});
						})
						.catch(function (error) {
							console.log('Error getting documents: ', error);
						});
				});
			} else {
				this.setState({ userEmail: '', isLogin: false });
			}
		});

		firebase
			.firestore()
			.collection('volunteers')
			.orderBy('isVolunteerApproved')
			.orderBy('nameOfVolunteer', 'asc')
			.onSnapshot((querySnapshot) => {
				const volunteerData = [];
				const volunteerDataId = [];

				querySnapshot.forEach(function (doc) {
					volunteerData.push(doc.data());
					volunteerDataId.push(doc.id);
				});

				this.setState({
					volunteerData: volunteerData,
					volunteerDataId: volunteerDataId,
					isLoading: false,
				});
			});
	}

	onVolunteerAdd = (e) => {
		e.preventDefault();

		const permissions = {
			editEvents: false,
			addEvents: false,
			editVolunteers: false,
			addVolunteers: false,
			admin: false,
		};

		const volunteerManageComponent = this;

		firebase.firestore().collection('volunteers').add({
			nameOfVolunteer: volunteerManageComponent.state.nameOfVolunteer,
			rankofVolunteer: volunteerManageComponent.state.rankofVolunteer,
			areaOfVolunteer: volunteerManageComponent.state.areaOfVolunteer,
			phoneNumberOfVolunteer: volunteerManageComponent.state.phoneNumberOfVolunteer,
			emailOfVolunteer: volunteerManageComponent.state.emailOfVolunteer,
			isVolunteerApproved: false,
			numberOfEventsOrganized: 0,
			numberOfPermissions: 0,
			permissions: permissions,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});

		this.addVolunteerModalOpen();
	};

	addVolunteerModalOpen = () => {
		this.setState({
			modal: !this.state.modal,
			nameOfVolunteer: '',
			rankofVolunteer: '',
			areaOfVolunteer: '',
			phoneNumberOfVolunteer: '',
			emailOfVolunteer: '',
			numOfEventsOrganizedByVolunteer: '',
		});
	};

	updateState(id, e) {
		const volunteerManageComponent = this;
		firebase
			.firestore()
			.collection('volunteers')
			.doc(id)
			.onSnapshot((doc) => {
				volunteerManageComponent.setState({
					nameOfVolunteer: doc.data().nameOfVolunteer,
					rankofVolunteer: doc.data().rankofVolunteer,
					areaOfVolunteer: doc.data().areaOfVolunteer,
					phoneNumberOfVolunteer: doc.data().phoneNumberOfVolunteer,
					emailOfVolunteer: doc.data().emailOfVolunteer,
					numOfEventsOrganizedByVolunteer: doc.data().numOfEventsOrganizedByVolunteer,
					update: true,
					updateId: id,
					modal: true,
				});
			});
	}

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	updateVolunteer(id) {
		const volunteerManageComponent = this;
		firebase
			.firestore()
			.collection('volunteers')
			.doc(id)
			.update({
				nameOfVolunteer: volunteerManageComponent.state.nameOfVolunteer === undefined ? '' : volunteerManageComponent.state.nameOfVolunteer,
				rankofVolunteer: volunteerManageComponent.state.rankofVolunteer === undefined ? '' : volunteerManageComponent.state.rankofVolunteer,
				areaOfVolunteer: volunteerManageComponent.state.areaOfVolunteer === undefined ? '' : volunteerManageComponent.state.areaOfVolunteer,
				phoneNumberOfVolunteer: volunteerManageComponent.state.phoneNumberOfVolunteer === undefined ? '' : volunteerManageComponent.state.phoneNumberOfVolunteer,
				emailOfVolunteer: volunteerManageComponent.state.emailOfVolunteer === undefined ? '' : volunteerManageComponent.state.emailOfVolunteer,
				numOfEventsOrganizedByVolunteer: volunteerManageComponent.state.numOfEventsOrganizedByVolunteer === undefined ? 0 : volunteerManageComponent.state.numOfEventsOrganizedByVolunteer,
			})
			.then(() => {
				volunteerManageComponent.setState({
					modal: false,
					nameOfVolunteer: '',
					rankofVolunteer: '',
					areaOfVolunteer: '',
					phoneNumberOfVolunteer: '',
					emailOfVolunteer: '',
					numOfEventsOrganizedByVolunteer: '',
					update: false,
					updateId: '',
				});

				volunteerManageComponent.renderSnackbar('Volunteer successfully updated!', 'success');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	}

	approveVolunteer = (documentId, emailOfVolunteer) => {
		const volunteerManageComponent = this;

		firebase
			.firestore()
			.collection('volunteers')
			.doc(documentId)
			.update({
				isVolunteerApproved: true,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then(function () {
				const user = {
					email: emailOfVolunteer,
					password: Math.floor(Math.random() * 16777215).toString(16),
				};

				axios.post(`http://localhost:5000/ploggers-of-india-website/us-central1/addUser`, { user }).then((res) => {
					volunteerManageComponent.renderSnackbar('Volunteer account created!', 'success');
				});

				volunteerManageComponent.renderSnackbar('Volunteer Approved Success!', 'success');
			});
	};

	handleDeleteClose = () => {
		this.setState({
			deleteDialogOpen: !this.state.deleteDialogOpen,
		});
	};

	deleteVolunteer = () => {
		const volunteerManageComponent = this;

		const email = volunteerManageComponent.state.volunteerData[volunteerManageComponent.state.deleteId].emailOfVolunteer;

		firebase
			.firestore()
			.collection('volunteers')
			.doc(this.state.volunteerDataId[this.state.deleteId])
			.delete()
			.then(() => {
				volunteerManageComponent.renderSnackbar('Volunteer successfully deleted!', 'success');
				const user = {
					email: email,
				};
				axios.post(`http://localhost:5000/ploggers-of-india-website/us-central1/deleteUserAuth`, { user }).then((res) => {
					// console.log(res);
					// console.log(res.data);
					volunteerManageComponent.renderSnackbar('Volunteer Deleted Successfully!', 'success');
				});
			})
			.catch((error) => {
				console.error('Error removing document: ', error);
				volunteerManageComponent.renderSnackbar('Error removing Volunteer!', 'error');
			});

		this.handleDeleteClose();
	};

	render() {
		const { classes } = this.props;

		const fields = [
			['Name Of Volunteer', 'nameOfVolunteer', this.state.nameOfVolunteer],
			['Rank Of Volunteer', 'rankofVolunteer', this.state.rankofVolunteer],
			['Area Of Volunteer', 'areaOfVolunteer', this.state.areaOfVolunteer],
			['Phone Number Of Volunteer', 'phoneNumberOfVolunteer', this.state.phoneNumberOfVolunteer],
			['Email ID Of Volunteer', 'emailOfVolunteer', this.state.emailOfVolunteer],
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<Navbar />

				<PageHeading name='Manage Volunteers' />

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin ? (
							<Container maxWidth='md' className={classes.root}>
								<Typography className={classes.manageButtonContainer}>
									{this.state.addVolunteers ? (
										<Button fullWidth variant='contained' color='primary' onClick={this.addVolunteerModalOpen} className={classes.manageButton}>
											Add new Volunteer
										</Button>
									) : null}
								</Typography>

								<Grid container spacing={2}>
									{this.state.volunteerData.length !== 0
										? this.state.volunteerData.map((data, index) => (
												<VolunteerCard
													data={data}
													editVolunteers={this.state.editVolunteers}
													admin={this.state.admin}
													update={this.updateState.bind(this)}
													documentId={this.state.volunteerDataId[index]}
													approve={!data.isVolunteerApproved}
													approveVolunteer={this.approveVolunteer.bind(this)}
													deleteVolunteer={() => this.setState({ deleteDialogOpen: true, deleteId: index })}
												/>
										  ))
										: null}
								</Grid>

								<Dialog onClose={this.handleDeleteClose} open={this.state.deleteDialogOpen}>
									<DialogTitle id='customized-dialog-title' onClose={this.handleDeleteClose}>
										Are you sure you want to delete ?
									</DialogTitle>

									<DialogActions>
										<Button onClick={() => this.deleteVolunteer()} variant='contained' fullWidth color='primary'>
											Delete It!
										</Button>
									</DialogActions>
								</Dialog>

								<Dialog open={this.state.modal} onClose={this.addVolunteerModalOpen} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
									<DialogTitle id='scroll-dialog-title'>Add new Volunteer</DialogTitle>

									<DialogContent>
										<DialogContentText id='scroll-dialog-description' tabIndex={-1}>
											<form noValidate autoComplete='on'>
												{fields.map((field) => (
													<TextField
														id='outlined-basic'
														label={field[0]}
														variant='outlined'
														name={field[1]}
														className={classes.inputField}
														value={field[2]}
														onChange={this.onChange}
													/>
												))}
											</form>
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										{this.state.update ? (
											<Button
												fullWidth
												onClick={this.updateVolunteer.bind(this, this.state.updateId)}
												variant='contained'
												className={classes.submitButton}
												color='primary'
												component='span'
											>
												Update
											</Button>
										) : (
											<Button fullWidth onClick={this.onVolunteerAdd} variant='contained' className={classes.submitButton} color='primary' component='span'>
												Submit
											</Button>
										)}
									</DialogActions>
								</Dialog>
							</Container>
						) : (
							<Container maxWidth='md'>
								<Typography gutterBottom></Typography>
								<Typography variant='h5' align='center'>
									Either you are logged out or you dont have a permission to view this page.
								</Typography>
							</Container>
						)}
					</>
				)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Home);
