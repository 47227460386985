import { firebase } from "./../Firebase";

const createHistory = require("history").createBrowserHistory;

export const SignOut = () => {
    localStorage.setItem("token", "");
    firebase
        .auth()
        .signOut()
        .then(function () {
            // Sign-out successful.
            console.log("Sign-out successful");
            let history = createHistory();
            history.push("/");
            let pathUrl = window.location.href;
            window.location.href = pathUrl;
        })
        .catch(function (error) {
            // An error happened.
        });
};
