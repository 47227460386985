import React from 'react';
import { Container, CssBaseline, makeStyles, Typography } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import PageHeading from './../Views/PageHeading';
import Footer from './../Views/Footer';

const useStyles = makeStyles((theme) => ({
	bgDiff: {
		backgroundColor: theme.palette.background.paper,
	},

	horizontalUnderline: {
		color: '#000',
		width: '60px',
		height: '3px',
		backgroundColor: '#000',
		marginTop: '-10px',
		margingBottom: theme.spacing(2),
		border: '#000',
	},
	container: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
	},
}));

const data = [
	[
		'Our Mission',
		'',
		'ACTION - small or big, is the Foundation of the World Action Org. It started with a dream of a Litter Free India. In Delhi back in 2017, a group of runners led by Ripu Daman Bevli started cleaning up their running routes post-training. It became a cool-down activity and soon other running groups across the country adopted it. And so came about the country’s first eco-fitness movement…. Read More',
		false,
	],
	[
		'About the Founder - Ripu Daman Bevli',
		'',
		'Starting with a crazy dream of a Litter Free India and world, he laid the foundations of the work we do today, which is relevant to the not just India but around the world. A passionate solo traveler, amater long distance runner and cyclist, it was one of this lond distance runs when the idea of picking up litter as a fitness workout came to him. Initially, building a community of runners to pick up litter, later came to be known as the Ploggers Of India community. He believes a clean-up itself doesn’t help. In his own words, ‘We will never be able to clean up our world by cleaning up, we will have to stop littering and creating waste.’ Living by the philosophy of the World Action Org, he executed a nation wide campaign, where he ran and cleaned up in 50 cities in about 50 days, joining hands with locals in each city to clean up their parks, streets, markets, beaches, hills, etc. It was an unprecedented campaign anywhere in the world. The Government of India adopted our mission during the campaign, and honored Ripu Daman Bevli with the title of the FIT India Ambassador with a mandate to take this to every district across the country.',
		true,
	],
	[
		'Leadership Team',
		'Neha SB - Ops Director',
		'Neha is an international certified wellness trainer and coach. She joined the movement in 2019 leading our efforts in NCR organizing Trash Workout workshops with schools and kids. She leads our Operations internally and communications externally.',
		false,
	],
];

export default function ButtonAppBar() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Navbar />
			<React.Fragment>
				<CssBaseline />
				<PageHeading name='About Us' />

				<Container maxWidth='xl' disableGutters>
					{data.map((data) => (
						<Typography gutterBottom className={!data[3] ? classes.bgDiff : null}>
							<Container maxWidth='lg' className={classes.container}>
								<Typography variant='h4' align='center' gutterBottom>
									{data[0]}
								</Typography>
								<hr className={classes.horizontalUnderline}></hr>
								<Typography variant='h6' align='left'>
									{data[1]}
								</Typography>
								<Typography variant='body2' align='justify'>
									{data[2]}
								</Typography>
							</Container>
						</Typography>
					))}
				</Container>
				<Footer />
			</React.Fragment>
		</div>
	);
}
