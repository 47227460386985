import React, { Component } from 'react';
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CssBaseline,
	Container,
	Button,
	withStyles,
	CardMedia,
	Typography,
	TextField,
	InputLabel,
	FormControl,
	Select,
} from '@material-ui/core';
import { firebase } from '../../Firebase';
import PageHeading from './../../Views/PageHeading';
import Snackbar from './../../Components/Snackbar';
import Loader from '../../Components/Loader';
import Navbar from '../../Views/Navbar/Navbar';
import NewsCard from '../../Components/NewsCard';
import BlogCard from '../../Components/BlogCard';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const styles = (theme) => ({
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	uploadButton: {
		width: '25%',
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(6),
		float: 'left',
	},
	submitButton: {
		width: '25%',
		marginTop: theme.spacing(1.1),
		marginBottom: theme.spacing(6),
		float: 'right',
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(6),
	},
	formImage: {
		height: 0,
		paddingTop: '56.25%', // 16:9
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(6),
		border: '2px solid',
	},
	displayArticles: {
		marginTop: theme.spacing(1),
	},
	dialogContent: {
		width: '100%',
	},
});

const reorder = (list, startIndex, endIndex) => {
	const [removed] = list.splice(startIndex, 1);
	list.splice(endIndex, 0, removed);

	return list;
};

class Permissions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLogin: false,
			userEmail: '',
			isAdminLogin: false,
			headline: '',
			date: '',
			readingTime: '',
			sourceOfNews: '',
			linkToArticle: '',
			description: '',
			imageURL: '',
			imageFileName: '',
			typeOfMedia: '',
			progress_image: 0,
			news: [],
			blogs: [],
			newsId: [],
			blogsId: [],
			articles: [],
			isLoading: true,
			update: false,
			updateId: '',
			snackbarOpen: false,
			articlesOpen: false,
			articleType: '',
		};

		this.onMediaSubmit = this.onMediaSubmit.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		const token = localStorage.getItem('token');

		if (token) {
			this.setState({
				isLogin: true,
			});
		} else {
			this.setState({
				isLogin: false,
			});
		}

		const mediaManageComponent = this;
		let isAdminLogin;
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({ userEmail: user.email }, function () {
					firebase
						.firestore()
						.collection('volunteers')
						.where('emailOfVolunteer', '==', this.state.userEmail)
						.get()
						.then(function (querySnapshot) {
							querySnapshot.forEach(function (doc) {
								isAdminLogin = doc.data().permissions['admin'];
							});
							mediaManageComponent.setState({
								isAdminLogin: isAdminLogin,
							});
							if (isAdminLogin) {
								firebase
									.firestore()
									.collection('media')
									.orderBy('id', 'desc')
									.onSnapshot((querySnapshot) => {
										var news = [];
										var blogs = [];
										var newsId = [];
										var blogsId = [];
										querySnapshot.forEach((doc) => {
											if (doc.data().typeOfMedia === 'news') {
												news.push(doc.data());
												newsId.push(doc.id);
											} else {
												blogs.push(doc.data());
												blogsId.push(doc.id);
											}
										});
										mediaManageComponent.setState({
											news: news,
											blogs: blogs,
											newsId: newsId,
											blogsId: blogsId,
											isLoading: false,
										});
									});
							} else {
								mediaManageComponent.setState({
									isLoading: false,
								});
							}
						})
						.catch(function (error) {
							console.log('Error getting documents: ', error);
						});
				});
			} else {
				this.setState({ userEmail: '', isLoading: false });
			}
		});
	}

	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		const mediaManageComponent = this;
		const id1 = this.state.news[result.source.index].id;
		const id2 = this.state.news[result.destination.index].id;

		const items = reorder(this.state.news, result.source.index, result.destination.index);

		this.setState(
			{
				articles: items,
			},
			function () {
				if (mediaManageComponent.state.articleType === 'news') {
					const docId1 = this.state.newsId[result.source.index];
					const docId2 = this.state.newsId[result.destination.index];

					firebase
						.firestore()
						.collection('media')
						.doc(docId1)
						.update({
							id: id2,
						})
						.then(() => {
							console.log('Document successfully updated!');
						})
						.catch((error) => {
							// The document probably doesn't exist.
							console.error('Error updating document: ', error);
						});

					firebase
						.firestore()
						.collection('media')
						.doc(docId2)
						.update({
							id: id1,
						})
						.then(() => {
							console.log('Document successfully updated!');
						})
						.catch((error) => {
							// The document probably doesn't exist.
							console.error('Error updating document: ', error);
						});
				}
			}
		);
	}

	onImageChange = async (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			this.setState({
				imageFileName: file.name,
			});
			const mediaManageComponent = this;
			var uploadTask = firebase
				.storage()
				.ref()
				.child('media/' + file.name)
				.put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				function (snapshot) {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState({ progress_image: progress });
					// console.log(this.state.progress_image);
					if (progress === 100) {
						var url;

						firebase
							.storage()
							.ref()
							.child('media/' + file.name)
							.getDownloadURL()
							.then(function (downloadURL) {
								console.log('File available at', downloadURL);
								url = downloadURL;
								mediaManageComponent.setState(
									{
										imageURL: url,
										snackbarOpen: true,
									},
									function () {
										setTimeout(function () {
											mediaManageComponent.setState({
												snackbarOpen: false,
											});
										}, 3000);
									}
								);
							});
					}
					console.log('Upload is ' + progress + '% done');
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log('Upload is paused');
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log('Upload is running');
							break;
						default:
							break;
					}
				}.bind(this),
				function (error) {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							console.log(error);
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;

						default:
							break;
					}
				}
			);
		} else {
			this.setState(() => ({ imageFileName: 'image' }));
		}
	};

	onMediaSubmit() {
		const mediaManageComponent = this;
		const { headline, date, readingTime, sourceOfNews, linkToArticle, imageURL, description, typeOfMedia } = this.state;
		firebase
			.firestore()
			.collection('media')
			.add({
				id: new Date().valueOf(),
				headline: headline,
				date: date,
				readingTime: readingTime,
				description: description,
				sourceOfNews: sourceOfNews,
				linkToArticle: linkToArticle,
				imageURL: imageURL,
				typeOfMedia: typeOfMedia,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then(function () {
				mediaManageComponent.setState({
					headline: '',
					date: '',
					readingTime: '',
					sourceOfNews: '',
					linkToArticle: '',
					description: '',
					imageURL: '',
					imageFileName: '',
					typeOfMedia: '',
					progress_image: 0,
				});
			});
	}

	handleArticles(type, e) {
		if (type === 'close') {
			this.setState({
				articlesOpen: false,
				articleType: '',
			});
		} else if (type === 'news') {
			this.setState({
				articles: this.state.news,
				articlesOpen: true,
				articleType: 'news',
			});
		} else if (type === 'blogs') {
			this.setState({
				articles: this.state.blogs,
				articlesOpen: true,
				articleType: 'blogs',
			});
		}
	}

	updateState(id, e) {
		const mediaManageComponent = this;
		firebase
			.firestore()
			.collection('media')
			.doc(id)
			.onSnapshot((doc) => {
				mediaManageComponent.setState({
					headline: doc.data().headline,
					date: doc.data().date,
					readingTime: doc.data().readingTime,
					sourceOfNews: doc.data().sourceOfNews,
					linkToArticle: doc.data().linkToArticle,
					description: doc.data().description,
					imageURL: doc.data().imageURL,
					typeOfMedia: doc.data().typeOfMedia,
					articlesOpen: false,
					update: true,
					updateId: id,
				});
			});
	}

	updateArticles(id, e) {
		const mediaManageComponent = this;
		firebase
			.firestore()
			.collection('media')
			.doc(id)
			.update({
				headline: mediaManageComponent.state.headline,
				date: mediaManageComponent.state.date,
				readingTime: mediaManageComponent.state.readingTime,
				sourceOfNews: mediaManageComponent.state.sourceOfNews,
				linkToArticle: mediaManageComponent.state.linkToArticle,
				description: mediaManageComponent.state.description,
				imageURL: mediaManageComponent.state.imageURL,
			})
			.then(() => {
				console.log('Document successfully updated!');
				mediaManageComponent.setState({
					headline: '',
					date: '',
					readingTime: '',
					sourceOfNews: '',
					linkToArticle: '',
					description: '',
					imageURL: '',
					imageFileName: '',
					typeOfMedia: '',
					progress_image: 0,
					update: false,
					updateId: '',
				});
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	}

	render() {
		const { classes } = this.props;

		const newsFields = [
			['Headline', 'headline', this.state.headline],
			['Date', 'date', this.state.date],
			['Reading Time', 'readingTime', this.state.readingTime],
			['Source', 'sourceOfNews', this.state.sourceOfNews],
			['Description', 'description', this.state.description],
			['Link to the Article', 'linkToArticle', this.state.linkToArticle],
		];

		return (
			<div>
				<Navbar />
				<React.Fragment>
					<CssBaseline />
					<Typography gutterBottom></Typography>
					<PageHeading name='Manage Media' />
					{this.state.snackbarOpen ? <Snackbar open='true' message='Image Upload Complete' severity='success' /> : null}

					{this.state.isLoading ? (
						<Loader />
					) : (
						<div>
							{this.state.isAdminLogin || this.state.isLogin ? (
								<Container maxWidth='md'>
									<Button variant='contained' onClick={this.handleArticles.bind(this, 'news')} color='primary' className={classes.displayArticles} fullWidth>
										Manage uploaded News
									</Button>
									<Button variant='contained' onClick={this.handleArticles.bind(this, 'blogs')} color='primary' className={classes.displayArticles} fullWidth>
										Manage uploaded Blogs
									</Button>
									<Typography align='center'>
										<form noValidate autoComplete='on'>
											{newsFields.map((field) => (
												<TextField
													id='outlined-basic'
													label={field[0]}
													variant='outlined'
													name={field[1]}
													className={classes.inputField}
													value={field[2]}
													onChange={this.onChange}
												/>
											))}
											<FormControl variant='outlined' className={classes.inputField}>
												<InputLabel htmlFor='outlined-age-native-simple'>Type of Media</InputLabel>
												<Select
													native
													value={this.state.typeOfMedia}
													onChange={this.onChange}
													label='Type of Media'
													inputProps={{
														name: 'typeOfMedia',
														id: 'outlined-age-native-simple',
													}}
												>
													<option aria-label='None' value='' />
													<option value='blog'>Blog</option>
													<option value='news'>News</option>
												</Select>
											</FormControl>

											<input accept='image/*' className={classes.input} id='contained-button-file' type='file' onChange={this.onImageChange} />
											<label htmlFor='contained-button-file'>
												<Button variant='contained' className={classes.uploadButton} color='primary' component='span'>
													Upload
												</Button>
											</label>
											<div
												className='upload_img_bar'
												style={{
													width: `${this.state.progress_image}%`,
													backgroundColor: this.state.progress_image === 100 ? 'rgb(68, 197, 85)' : 'rgb(42, 160, 175)',
													display: 'block',
													height: '3px',
												}}
											></div>

											{this.state.update ? (
												<Button
													onClick={this.updateArticles.bind(this, this.state.updateId)}
													variant='contained'
													className={classes.submitButton}
													color='primary'
													component='span'
												>
													Update
												</Button>
											) : (
												<Button onClick={this.onMediaSubmit} variant='contained' className={classes.submitButton} color='primary' component='span'>
													Submit
												</Button>
											)}
										</form>
										<CardMedia className={classes.formImage} image={this.state.imageURL} title='Blog Image' />
										{/* {this.state.imageURL ? <CardMedia className={classes.formImage} image={this.state.imageURL} title="Blog Image" /> : null} */}
									</Typography>
								</Container>
							) : (
								<Container maxWidth='md'>
									<Typography gutterBottom></Typography>
									<Typography variant='h5' align='center'>
										Either you are logged out or you dont have a permission to view this page.
									</Typography>
								</Container>
							)}
						</div>
					)}

					{this.state.articlesOpen ? (
						<Dialog
							maxWidth='md'
							open={this.state.articlesOpen}
							onClose={this.handleArticles.bind(this, 'close')}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
						>
							<DialogTitle id='alert-dialog-title'>Articles</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
								</DialogContentText>
								{this.state.articleType === 'news' ? (
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Droppable droppableId='droppable'>
											{(provided) => {
												this.state.news.map((data, index) => (
													<NewsCard
														id={data.id}
														index={index}
														className={classes.dialogContent}
														date={data.date}
														image={data.imageURL}
														description={data.description}
														link={data.linkToArticle}
														headline={data.headline}
														readingTime={data.readingTime}
														source={data.sourceOfNews}
														admin='true'
														provided={provided}
														documentId={this.state.newsId[index]}
														update={this.updateState.bind(this)}
													/>
												));
											}}
										</Droppable>
									</DragDropContext>
								) : (
									this.state.articles.map((data, index) => (
										<BlogCard
											key={index}
											date={data.date}
											image={data.imageURL}
											description={data.description}
											link={data.linkToArticle}
											headline={data.headline}
											admin='true'
											documentId={this.state.blogsId[index]}
											update={this.updateState.bind(this)}
										/>
									))
								)}
							</DialogContent>
						</Dialog>
					) : null}
				</React.Fragment>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Permissions);
