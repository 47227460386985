import React, { Component } from 'react';
import Navbar from '../../Views/Navbar/NavbarAdHome';
import {
	FormControlLabel,
	ListItemText,
	Divider,
	Grid,
	ListItem,
	List,
	Checkbox,
	Container,
	withStyles,
	Button,
	Typography,
	CssBaseline,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { firebase } from '../../Firebase';
import Snackbar from './../../Components/Snackbar';
import Loader from '../../Components/Loader';
import PageHeading from './../../Views/PageHeading';

const styles = (theme) => ({
	submitButton: {
		width: '150px',
		marginTop: theme.spacing(2),
		float: 'right',
		marginLeft: theme.spacing(8),
	},
	editButton: {
		width: '150px',
		marginTop: theme.spacing(2),
		float: 'left',
		marginRight: theme.spacing(8),
	},
});

class Permissions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAdminLogin: false,
			nameOfVolunteer: '',
			volunteerData: [],
			volunteerDataId: [],
			editEvents: false,
			addEvents: false,
			editVolunteers: false,
			addVolunteers: false,
			admin: false,
			id: '',
			isLoading: true,
			permissionsEditable: false,
			snackbarOpen: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			permissionDialogOpen: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	handleCheckboxChange = (e) =>
		this.setState({
			[e.target.name]: e.target.checked,
		});

	componentDidMount() {
		const permissionsManageComponent = this;
		let isAdminLogin;

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				firebase
					.firestore()
					.collection('volunteers')
					.where('emailOfVolunteer', '==', user.email)
					.get()
					.then(function (querySnapshot) {
						querySnapshot.forEach(function (doc) {
							isAdminLogin = doc.data().permissions['admin'];
						});
						permissionsManageComponent.setState({
							isAdminLogin: isAdminLogin,
						});
					})
					.catch(function (error) {
						console.log('Error getting documents: ', error);
					});
			}
		});

		firebase
			.firestore()
			.collection('volunteers')
			.orderBy('nameOfVolunteer', 'asc')
			.onSnapshot((querySnapshot) => {
				const volunteerData = [];
				const volunteerDataId = [];

				querySnapshot.forEach(function (doc) {
					if (doc.data().isVolunteerApproved) {
						volunteerData.push(doc.data());
						volunteerDataId.push(doc.id);
					}
				});

				this.setState({ volunteerData: volunteerData, volunteerDataId: volunteerDataId, isLoading: false });
			});
	}

	onVolunteerModalOpen = () => {
		this.setState({
			permissionDialogOpen: !this.state.permissionDialogOpen,
			permissionsEditable: false,
		});
	};

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	onVolunteerPermissionChange = (e) => {
		e.preventDefault();

		const permissionsManageComponent = this;

		const editEvents = this.state.editEvents;
		const addEvents = this.state.addEvents;
		const editVolunteers = this.state.editVolunteers;
		const addVolunteers = this.state.addVolunteers;
		const admin = this.state.admin;

		const permissions = [editVolunteers, addVolunteers, admin, addEvents, editEvents];

		let number = 0;

		permissions.forEach((permission) => {
			if (permission) {
				number += 1;
			}
		});

		firebase
			.firestore()
			.collection('volunteers')
			.doc(this.state.id)
			.update({
				'permissions.editEvents': editEvents,
				'permissions.addEvents': addEvents,
				'permissions.editVolunteers': editVolunteers,
				'permissions.addVolunteers': addVolunteers,
				'permissions.admin': admin,
				numberOfPermissions: number,
			})
			.then(function () {
				permissionsManageComponent.renderSnackbar('Permission successfully updated!', 'success');
			});

		this.onVolunteerModalOpen();
	};

	updateVolunteerId = (index, e) => {
		const id = this.state.volunteerDataId[index];
		const editEvents = this.state.volunteerData[index].permissions['editEvents'];
		const addEvents = this.state.volunteerData[index].permissions['addEvents'];
		const editVolunteers = this.state.volunteerData[index].permissions['editVolunteers'];
		const addVolunteers = this.state.volunteerData[index].permissions['addVolunteers'];
		const admin = this.state.volunteerData[index].permissions['admin'];
		this.setState({
			id: id,
			permissionDialogOpen: !this.state.permissionDialogOpen,
			editEvents: editEvents,
			addEvents: addEvents,
			editVolunteers: editVolunteers,
			addVolunteers: addVolunteers,
			admin: admin,
		});
	};

	handlePermissionClose = () => {
		this.setState({
			permissionDialogOpen: !this.state.permissionDialogOpen,
		});
	};

	render() {
		const { classes } = this.props;

		const permissionsList = [
			[this.state.editEvents, 'editEvents', 'Edit Events'],
			[this.state.addEvents, 'addEvents', 'Add Events'],
			[this.state.editVolunteers, 'editVolunteers', 'Edit Volunteers'],
			[this.state.addVolunteers, 'addVolunteers', 'Add Volunteers'],
			[this.state.admin, 'admin', 'Admin'],
		];

		return (
			<div>
				<Navbar />
				<React.Fragment>
					<CssBaseline />
					{this.state.snackbarOpen ? (
						<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
					) : null}

					<PageHeading name='Manage Permissions' />

					{this.state.isLoading ? (
						<Loader />
					) : (
						<>
							{this.state.isAdminLogin ? (
								<Container maxWidth='lg'>
									<List component='nav'>
										<Grid container spacing={2}>
											{this.state.volunteerData.length !== 0
												? this.state.volunteerData.map((data, index) => (
														<Grid item xs={12} sm={4}>
															<ListItem button>
																<ListItemText primary={data.nameOfVolunteer} secondary={data.emailOfVolunteer} />
																<ListItemText primary={data.numberOfPermissions} />
																<Button variant='outlined' color='primary' onClick={this.updateVolunteerId.bind(this, index)}>
																	View
																</Button>
															</ListItem>
															<Divider />
														</Grid>
												  ))
												: null}
										</Grid>
									</List>

									<Dialog onClose={this.handlePermissionClose} open={this.state.permissionDialogOpen}>
										<DialogTitle id='customized-dialog-title' onClose={this.handlePermissionClose}>
											Edit Permissions
										</DialogTitle>

										<DialogContent>
											{permissionsList.map((handle) => (
												<>
													<FormControlLabel
														control={
															<Checkbox
																checked={handle[0]}
																onChange={this.handleCheckboxChange}
																name={handle[1]}
																color='primary'
																disabled={!this.state.permissionsEditable}
															/>
														}
														label={handle[2]}
													/>
													<br></br>
												</>
											))}
										</DialogContent>

										<DialogActions>
											<Button
												variant='contained'
												color='secondary'
												onClick={() =>
													this.setState({
														permissionsEditable: true,
													})
												}
												className={classes.editButton}
											>
												Edit
											</Button>
											<Button variant='contained' color='secondary' onClick={this.onVolunteerPermissionChange} className={classes.submitButton}>
												Submit
											</Button>
										</DialogActions>
									</Dialog>
								</Container>
							) : (
								<Container maxWidth='md'>
									<Typography gutterBottom></Typography>
									<Typography variant='h5' align='center'>
										Either you are logged out or you dont have a permission to view this page.
									</Typography>
								</Container>
							)}
						</>
					)}
				</React.Fragment>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Permissions);
