import React from 'react';
import Loader from './../Components/Loader';
import { Container, CssBaseline, withStyles } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import { firebase } from '../Firebase';
import EventCard from '../Components/EventCard';
import PageHeading from '../Views/PageHeading';
import Footer from '../Views/Footer';

const styles = () => ({
	container: {
		minHeight: '60vh',
	},
});

class ButtonAppBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			eventData: [],
			isLoading: true,
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection('events')
			.orderBy('timestamp', 'desc')
			.onSnapshot((querySnapshot) => {
				const Matches = [];

				querySnapshot.forEach(function (doc) {
					let eventData = doc.data();
					eventData.Id = doc.id;
					Matches.push(eventData);
				});

				this.setState({ eventData: Matches, isLoading: false });
			});
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Navbar />
				<CssBaseline />
				<PageHeading name='Events' />
				<Container maxWidth='md' className={classes.container}>
					{this.state.isLoading ? (
						<Loader />
					) : (
						<React.Fragment>{this.state.eventData.length !== 0 ? this.state.eventData.map((data) => <EventCard data={data} key={data.Id} />) : null}</React.Fragment>
					)}
				</Container>
				<Footer />
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ButtonAppBar);
