import React, { Component } from 'react';
import {
	DialogActions,
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogContentText,
	CssBaseline,
	Button,
	withStyles,
	TextField,
	InputLabel,
	FormControl,
	Select,
	Container,
} from '@material-ui/core';
import Navbar from '../../Views/Navbar/NavbarAdHome';
import { firebase } from '../../Firebase';
import Snackbar from './../../Components/Snackbar';
import Loader from '../../Components/Loader';
import PageHeading from './../../Views/PageHeading';
import EventCard from './../../Components/EventCard';

const styles = (theme) => ({
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submitButton: {
		width: '150px',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		float: 'right',
	},
	input: {
		display: 'none',
	},
	uploadButton: {
		width: '150px',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		float: 'left',
	},
	manageButton: {
		marginTop: theme.spacing(1),
	},
});

class EventsManage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			eventData: [],
			eventDataId: [],
			isLogin: false,
			userEmail: '',
			editEvents: false,
			addEvents: false,
			admin: false,
			imageFileName: '',
			progress_image: 0,
			imageURL: '',
			snackbarOpen: false,
			snackbarSeverity: '',
			snackbarMessage: '',
			nameOfEvent: '',
			date: '',
			time: '',
			extraInfo: '',
			city: '',
			regButLink: '',
			mapLink: '',
			update: false,
			updateId: '',
			selectedVolunteerId: '',
			deleteId: '',
			volunteerData: [],
			volunteerDataId: [],
			isLoading: true,
			modal: false,
			deleteDialogOpen: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		let editEvents, addEvents, admin;
		const eventsComponent = this;

		firebase
			.firestore()
			.collection('events')
			.orderBy('isEventApproved')
			.orderBy('timestamp', 'desc')
			.onSnapshot((querySnapshot) => {
				const eventData = [];
				const eventDataId = [];

				querySnapshot.forEach(function (doc) {
					eventData.push(doc.data());
					eventDataId.push(doc.id);
				});

				this.setState({
					eventData: eventData,
					eventDataId: eventDataId,
				});
			});

		firebase
			.firestore()
			.collection('volunteers')
			.orderBy('nameOfVolunteer', 'asc')
			.onSnapshot((querySnapshot) => {
				const volunteerData = [];
				const volunteerDataId = [];

				querySnapshot.forEach(function (doc) {
					if (doc.data().isVolunteerApproved) {
						volunteerData.push(doc.data());
						volunteerDataId.push(doc.id);
					}
				});

				this.setState({ volunteerData: volunteerData, volunteerDataId: volunteerDataId, isLoading: false });
			});

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({ userEmail: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('volunteers')
						.where('emailOfVolunteer', '==', this.state.userEmail)
						.get()
						.then(function (querySnapshot) {
							querySnapshot.forEach(function (doc) {
								editEvents = doc.data().permissions['editEvents'];
								addEvents = doc.data().permissions['addEvents'];
								admin = doc.data().permissions['admin'];
							});

							eventsComponent.setState({
								editEvents: editEvents,
								addEvents: addEvents,
								admin: admin,
							});
						})
						.catch(function (error) {
							console.log('Error getting documents: ', error);
						});
				});
			} else {
				this.setState({ userEmail: '', isLogin: false });
			}
		});
	}

	onImageChange = async (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			this.setState({
				imageFileName: file.name,
			});
			const eventsComponent = this;
			var uploadTask = firebase
				.storage()
				.ref()
				.child('events/' + file.name)
				.put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				function (snapshot) {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState({ progress_image: progress });
					// console.log(this.state.progress_image);
					if (progress === 100) {
						var url;

						firebase
							.storage()
							.ref()
							.child('events/' + file.name)
							.getDownloadURL()
							.then(function (downloadURL) {
								console.log('File available at', downloadURL);
								url = downloadURL;
								eventsComponent.setState({
									imageURL: url,
								});
								eventsComponent.renderSnackbar('Image Upload Complete', 'success');
							});
					}
					console.log('Upload is ' + progress + '% done');
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log('Upload is paused');
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log('Upload is running');
							break;
						default:
							break;
					}
				}.bind(this),
				function (error) {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							console.log(error);
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;

						default:
							break;
					}
				}
			);
		} else {
			this.setState(() => ({ imageFileName: 'image' }));
		}
	};

	updateState(id) {
		const eventsComponent = this;
		firebase
			.firestore()
			.collection('events')
			.doc(id)
			.onSnapshot((doc) => {
				eventsComponent.setState({
					nameOfEvent: doc.data().name,
					date: doc.data().date,
					time: doc.data().time,
					extraInfo: doc.data().extraInfo,
					city: doc.data().city,
					regButLink: doc.data().regButLink,
					mapLink: doc.data().mapLink,
					selectedVolunteerId: doc.data().selectedVolunteerId,
					imageURL: doc.data().imageURL,
					update: true,
					updateId: id,
					modal: true,
				});
			});
	}

	approveEvent = (documentId, selectedVolunteerId) => {
		const eventsComponent = this;

		const batch = firebase.firestore().batch();

		const volunteerReference = firebase.firestore().collection('volunteers').doc(selectedVolunteerId);
		batch.update(volunteerReference, { numberOfEventsOrganized: firebase.firestore.FieldValue.increment(1) });

		const eventsReference = firebase.firestore().collection('events').doc(documentId);
		batch.update(eventsReference, { isEventApproved: true, timestamp: firebase.firestore.FieldValue.serverTimestamp() });

		batch.commit().then(() => {
			eventsComponent.renderSnackbar('Event Approved Successfully!', 'success');
		});
	};

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		const eventsComponent = this;
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	deleteEvent = () => {
		const eventsComponent = this;

		const batch = firebase.firestore().batch();

		const volunteerReference = firebase.firestore().collection('volunteers').doc(eventsComponent.state.selectedVolunteerId);
		batch.update(volunteerReference, { numberOfEventsOrganized: firebase.firestore.FieldValue.increment(-1) });

		const eventsReference = firebase.firestore().collection('events').doc(eventsComponent.state.deleteId);
		batch.delete(eventsReference);

		batch.commit().then(() => {
			eventsComponent.renderSnackbar('Event Deleted Successfully!', 'success');
		});

		this.handleDeleteClose();
	};

	updateEvent(id) {
		const eventsComponent = this;
		firebase
			.firestore()
			.collection('events')
			.doc(id)
			.update({
				name: eventsComponent.state.nameOfEvent,
				date: eventsComponent.state.date,
				time: eventsComponent.state.time,
				extraInfo: eventsComponent.state.extraInfo,
				city: eventsComponent.state.city,
				imageURL: eventsComponent.state.imageURL,
				regButLink: eventsComponent.state.regButLink,
				mapLink: eventsComponent.state.mapLink,
				selectedVolunteerId: eventsComponent.state.selectedVolunteerId,
			})
			.then(() => {
				eventsComponent.setState({
					modal: false,
					nameOfEvent: '',
					date: '',
					time: '',
					extraInfo: '',
					image: '',
					imageURL: '',
					city: '',
					regButLink: '',
					mapLink: '',
					selectedVolunteerId: '',
					update: false,
					updateId: '',
				});

				eventsComponent.renderSnackbar('Event Updated Successfully!', 'success');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				eventsComponent.renderSnackbar('Event Update Error!', 'error');
			});
	}

	onEventAdd = (e) => {
		e.preventDefault();

		const eventsComponent = this;

		firebase.firestore().collection('events').add({
			name: eventsComponent.state.nameOfEvent,
			date: eventsComponent.state.date,
			time: eventsComponent.state.time,
			extraInfo: eventsComponent.state.extraInfo,
			city: eventsComponent.state.city,
			imageURL: eventsComponent.state.imageURL,
			isEventApproved: false,
			regButLink: eventsComponent.state.regButLink,
			mapLink: eventsComponent.state.mapLink,
			selectedVolunteerId: eventsComponent.state.selectedVolunteerId,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});

		this.addEventModalOpen();
	};

	addEventModalOpen = () => {
		if (this.state.modal) {
			this.setState({
				modal: false,
				nameOfEvent: '',
				date: '',
				time: '',
				extraInfo: '',
				image: '',
				imageURL: '',
				city: '',
				regButLink: '',
				mapLink: '',
				selectedVolunteerId: '',
				update: false,
				updateId: '',
			});
		} else {
			this.setState({
				modal: true,
			});
		}
	};

	handleDeleteClose = () => {
		this.setState({
			deleteDialogOpen: !this.state.deleteDialogOpen,
		});
	};

	render() {
		const { classes } = this.props;

		const fields = [
			['Name of Event', 'nameOfEvent', this.state.nameOfEvent],
			['City', 'city', this.state.city],
			['Extra Information', 'extraInfo', this.state.extraInfo],
			['Register Button Link', 'regButLink', this.state.regButLink],
			['Map Link', 'mapLink', this.state.mapLink],
			['Date', 'date', this.state.date],
			['Time', 'time', this.state.time],
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<Navbar />
				<PageHeading name='Manage Events' />

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin ? (
							<Container maxWidth='md' className={classes.root}>
								{this.state.addEvents ? (
									<Button fullWidth variant='contained' color='primary' onClick={this.addEventModalOpen} className={classes.manageButton}>
										Add new Event
									</Button>
								) : null}

								{this.state.eventData.length !== 0
									? this.state.eventData.map((data, index) => (
											<EventCard
												key={index}
												data={data}
												editEvents={this.state.editEvents}
												admin={this.state.admin}
												selectedEventId={index}
												update={this.updateState.bind(this)}
												documentId={this.state.eventDataId[index]}
												approveState={!data.isEventApproved}
												approveEvent={this.approveEvent.bind(this)}
												deleteEvent={() => this.setState({ deleteDialogOpen: true, deleteId: this.state.eventDataId[index], selectedVolunteerId: data.selectedVolunteerId })}
											/>
									  ))
									: null}

								<Dialog onClose={this.handleDeleteClose} open={this.state.deleteDialogOpen}>
									<DialogTitle id='customized-dialog-title' onClose={this.handleDeleteClose}>
										Are you sure you want to delete ?
									</DialogTitle>

									<DialogActions>
										<Button onClick={() => this.deleteEvent()} variant='contained' fullWidth color='primary'>
											Delete It!
										</Button>
									</DialogActions>
								</Dialog>

								<Dialog open={this.state.modal} onClose={this.addEventModalOpen}>
									<DialogTitle id='scroll-dialog-title'>Add new Event</DialogTitle>
									<DialogContent>
										<DialogContentText id='scroll-dialog-description' tabIndex={-1}>
											{fields.map((data, index) => (
												<TextField key={index} label={data[0]} variant='outlined' name={data[1]} className={classes.inputField} value={data[2]} onChange={this.onChange} />
											))}

											<FormControl variant='outlined' className={classes.inputField}>
												<InputLabel htmlFor='outlined-age-native-simple'>Volunteer ?</InputLabel>
												<Select
													native
													value={this.state.selectedVolunteerId}
													onChange={this.onChange}
													label='Volunteer ?'
													inputProps={{
														name: 'selectedVolunteerId',
														id: 'outlined-age-native-simple',
													}}
												>
													<option aria-label='None' value='' />
													{this.state.volunteerData.length !== 0
														? this.state.volunteerData.map((data, index) => (
																<option key={index} value={this.state.volunteerDataId[index]}>
																	{data.nameOfVolunteer}
																</option>
														  ))
														: null}
												</Select>
											</FormControl>

											<input accept='image/*' className={classes.input} id='contained-button-file' multiple type='file' onChange={this.onImageChange} />
											<label htmlFor='contained-button-file'>
												<Button variant='contained' className={classes.uploadButton} color='primary' component='span'>
													Upload
												</Button>
											</label>
											<div
												className='upload_img_bar'
												style={{
													width: `${this.state.progress_image}%`,
													backgroundColor: this.state.progress_image === 100 ? 'rgb(68, 197, 85)' : 'rgb(42, 160, 175)',
													display: 'block',
													height: '3px',
												}}
											></div>

											{this.state.update ? (
												<Button
													onClick={this.updateEvent.bind(this, this.state.updateId)}
													variant='contained'
													className={classes.submitButton}
													color='primary'
													component='span'
												>
													Update
												</Button>
											) : (
												<Button onClick={this.onEventAdd} variant='contained' className={classes.submitButton} color='primary' component='span'>
													Submit
												</Button>
											)}
										</DialogContentText>
									</DialogContent>
								</Dialog>
							</Container>
						) : (
							<Container maxWidth='md'>
								<Typography gutterBottom></Typography>
								<Typography variant='h5' align='center'>
									Either you are logged out or you dont have a permission to view this page.
								</Typography>
							</Container>
						)}
					</>
				)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(EventsManage);
