import React from 'react';
import { makeStyles, Grid, CssBaseline, Container, Typography } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import PageHeading from './../Views/PageHeading';
import coverImage from '../res/img/logo-transparent.png';
import HoverImage from 'react-hover-image';
import Footer from '../Views/Footer';

import sdg3 from '../res/img/SDG3.png';
import sdg4 from '../res/img/SDG4.png';
import sdg11 from '../res/img/SDG11.png';
import sdg12 from '../res/img/SDG12.png';
import sdg13 from '../res/img/SDG13.png';
import bg2 from '../res/img/bg2.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	grid: {
		backgroundColor: theme.palette.background.paper,
		textAlign: 'center',
	},
	text: {
		textAlign: 'justify',
		textJustify: 'inter-word',
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(8),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(8),
		},
		[theme.breakpoints.only('xs')]: {
			margin: theme.spacing(2),
			marginTop: theme.spacing(6),
			paddingBottom: theme.spacing(0),
		},
	},
	videos: {
		textAlign: 'center',
		aspectRatio: '4/3',
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingRight: theme.spacing(8),
		},
		[theme.breakpoints.only('xs')]: {
			margin: theme.spacing(2),
			marginTop: theme.spacing(2),
			paddingBottom: theme.spacing(4),
		},
	},
	body: {
		padding: theme.spacing(8),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
			marginTop: theme.spacing(6),
		},
	},
	gridTile: {
		width: '100%',
		aspectRatio: '1/1',
	},
}));

export default function PlasticWaste() {
	const classes = useStyles();

	const gridData = [
		{
			image: sdg3,
			text: 'Good health',
		},
		{
			image: sdg4,
			text: 'Quality Education',
		},
		{
			image: sdg11,
			text: 'Sustainable Cities',
		},
		{
			image: sdg12,
			text: 'Responsible Consumption',
		},
		{
			image: sdg13,
			text: 'Climate Action',
		},
		{
			image: bg2,
			text: 'Some background image',
		},
		{
			image: sdg11,
			text: 'Sustainable Cities',
		},
		{
			image: sdg12,
			text: 'Responsible Consumption',
		},
	];

	return (
		<div className={classes.root}>
			<Navbar />
			<React.Fragment>
				<CssBaseline />
				<PageHeading name='Plastic Waste' />
				<Grid container spacing={4} className={classes.grid}>
					<Grid item xs={12} sm={6}>
						<Typography variant='body1' className={classes.text}>
							Plastic and packaging is omni-present. So how do you shun plastic from your life? Plastic Upvaas pledge gives you a direction so that you can identify one thing that you
							could easily shun from your life. One item to start with, that is single use. In the Covid times, we are asking you to shun surgical masks and instead, choose reusable
							cloth masks….
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.videos}>
							<iframe
								title='Pledge towards a cleaner future'
								width='100%'
								height='100%'
								src='https://www.youtube.com/embed/Pqq2kAANMSw'
								frameBorder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</div>
					</Grid>
				</Grid>
				<Container maxWidth='lg' className={classes.body}>
					<Grid container spacing={4}>
						{gridData.map((tile) => (
							<Grid item xs={6} sm={3}>
								<HoverImage src={coverImage} hoverSrc={tile.image} className={classes.gridTile} />
							</Grid>
						))}
					</Grid>
				</Container>
				<Footer />
			</React.Fragment>
		</div>
	);
}
