import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { firebase } from '../../Firebase';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { SignOut } from './../../Components/Signout';
import logo from './../../res/img/logo-transparent.png';

const drawerWidth = 300;
const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
	},
	listItemButton: {
		width: '100px',
		marginTop: '0px',
		marginBottom: '0px',
	},
	logo: {
		padding: theme.spacing(1),
		maxWidth: '110px',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '80px',
		},
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		textAlign: 'center',
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
});

const createHistory = require('history').createBrowserHistory;

class NavbarAdHome extends Component {
	constructor(props) {
		super(props);

		this.state = {
			drawerOpen: false,
			admin: false,
			isLogin: false,
		};

		this.changeDrawerState = this.changeDrawerState.bind(this);
	}

	componentDidMount() {
		this.setState({ isLogin: localStorage.getItem('token') ? true : false });
		var navbarAdHomeComponent = this;
		var admin;
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({ userEmail: user.email }, function () {
					firebase
						.firestore()
						.collection('volunteers')
						.where('emailOfVolunteer', '==', this.state.userEmail)
						.get()
						.then(function (querySnapshot) {
							querySnapshot.forEach(function (doc) {
								admin = doc.data().permissions['admin'];
							});
							navbarAdHomeComponent.setState({
								admin: admin,
							});
						})
						.catch(function (error) {
							console.log('Error getting documents: ', error);
						});
				});
			} else {
				this.setState({ userEmail: '' });
			}
		});
	}

	signout(e) {
		e.preventDefault();
		firebase
			.auth()
			.signOut()
			.then(function () {
				console.log('Sign-out successful');
				localStorage.setItem('token', '');
				let history = createHistory();
				history.push('/admin');
				let pathUrl = window.location.href;
				window.location.href = pathUrl;
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	changeDrawerState() {
		if (this.state.drawerOpen) {
			this.setState({ drawerOpen: false });
		} else {
			this.setState({ drawerOpen: true });
		}
	}

	render() {
		const { classes } = this.props;

		const navOptions = [
			['Profile', '/profile'],
			['Events', '/admin/events-manage'],
			['Volunteer', '/admin/volunteer-manage'],
			['Permissions', '/admin/permission-manage'],
			['Media', '/admin/media-manage'],
		];

		const socialHandles = [
			['Facebook', <FacebookIcon />, 'https://fb.com'],
			['Twitter', <TwitterIcon />, 'https://twitter.com'],
			['Instagram', <InstagramIcon />, 'https://instagram.com'],
			['YouTube', <YouTubeIcon />, 'https://youtube.com'],
		];

		return (
			<div>
				<AppBar className={classes.appbar} color='secondary'>
					<Toolbar>
						<IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu' onClick={this.changeDrawerState}>
							<MenuIcon />
						</IconButton>

						<div className={classes.title}>
							<Button href='/' color='inherit' className={classes.disableHover} disableRipple disableFocusRipple>
								<img src={logo} alt='Logo' className={classes.logo} />
							</Button>
						</div>
						{/* <Button key='Volunteer' href={this.state.isLogin ? '/profile' : '/get-involved/volunteer'} color='primary' variant='contained'>
							{this.state.isLogin ? 'Profile' : 'Get Involved'}
						</Button> */}
					</Toolbar>
				</AppBar>

				<Drawer
					className={classes.drawer}
					variant='persistent'
					anchor='left'
					open={this.state.drawerOpen}
					onClose={this.changeDrawerState}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<div className={classes.drawerHeader}>
						<IconButton onClick={this.changeDrawerState}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<List component='nav' aria-label='navigation pane'>
						{navOptions.map((option) => (
							<ListItem button component='a' href={option[1]} key={option[0]}>
								<ListItemText align='center' primary={option[0]} />
							</ListItem>
						))}
					</List>
					<ListItem button onClick={() => SignOut()}>
						<ListItemText align='center' primary='Sign Out' />
					</ListItem>
					<List component='nav' aria-label='social media link tree'>
						{socialHandles.map((option) => (
							<IconButton href={option[2]} className={classes.socialIcons}>
								{option[1]}
							</IconButton>
						))}
					</List>
				</Drawer>
			</div>
		);
	}
}

export default withStyles(useStyles, { withTheme: true })(NavbarAdHome);
