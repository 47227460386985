import React from 'react';
import { withStyles, Snackbar, Link, TextField, Button, Typography, CssBaseline, Container } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import { firebase } from '../Firebase';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';
import PageHeading from './../Views/PageHeading';

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '600px',
	},
	inputField: {
		width: '100%',
		marginTop: '10px',
	},
	submitButton: {
		marginTop: '15px',
	},
	closeModalButton: {
		float: 'right',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '-10px',
	},
	border: {
		borderBottom: '1px solid lightgray',
		width: '100%',
	},
	content: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		fontSize: 22,
		color: 'lightgray',
	},
});

class ForgotPasswords extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			successSnackbarOpen: false,
			errorSnackbarOpen: false,
		};

		this.resetPassword = this.resetPassword.bind(this);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	resetPassword(e) {
		e.preventDefault();
		const forgotPasswordComponent = this;

		firebase
			.auth()
			.sendPasswordResetEmail(this.state.email)
			.then(function () {
				forgotPasswordComponent.setState({
					successSnackbarOpen: true,
				});
			})
			.catch(function (error) {
				forgotPasswordComponent.setState({
					errorSnackbarOpen: true,
				});
			});

		this.setState({ email: '' });
	}

	handleSnackbarClose(e) {
		e.preventDefault();

		this.setState({
			successSnackbarOpen: false,
			errorSnackbarOpen: false,
		});
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<React.Fragment>
					<CssBaseline />
					<Navbar />
					<PageHeading name='Forget Password' />
					<Container maxWidth='md'>
						<Typography variant='h5' align='center' gutterBottom>
							<Container maxWidth='xs'>
								<TextField
									id='outlined-basic'
									type='email'
									name='email'
									label='Registered Email'
									variant='outlined'
									onChange={this.onChange}
									value={this.state.email}
									className={classes.inputField}
									required
								/>
								<Button variant='contained' color='primary' onClick={this.resetPassword} fullWidth className={classes.submitButton}>
									Reset Password
								</Button>
								<div className={classes.container}>
									<div className={classes.border} />
									<span className={classes.content}>or</span>
									<div className={classes.border} />
								</div>
								<Typography align='left' variant='subtitle2'>
									Remember it ? <Link href='/get-involved/volunteer'>Login</Link>
								</Typography>
							</Container>
						</Typography>
					</Container>
					{this.state.errorSnackbarOpen ? (
						<Snackbar open={this.state.errorSnackbarOpen} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
							<MuiAlert onClose={this.handleSnackbarClose} severity='error' elevation={6} variant='filled'>
								There is no user record corresponding to this identifier. The user may have been deleted.
							</MuiAlert>
						</Snackbar>
					) : null}
					{this.state.successSnackbarOpen ? (
						<Snackbar open={this.state.successSnackbarOpen} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
							<Alert onClose={this.handleSnackbarClose} severity='success' elevation={6} variant='filled'>
								Check your Inbox for the Link to Reset Your Password!
							</Alert>
						</Snackbar>
					) : null}
				</React.Fragment>
			</div>
		);
	}
}

export default withStyles(useStyles, { withTheme: true })(ForgotPasswords);
