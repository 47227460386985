import React from 'react';
import Carousel from 'react-slick';
import { makeStyles, Button, Typography } from '@material-ui/core';

import home1 from '../res/img/homePage/1.jpg';
import home2 from '../res/img/homePage/2.jpg';
import home3 from '../res/img/homePage/3.jpg';
import home4 from '../res/img/homePage/4.jpg';
import home5 from '../res/img/homePage/5.jpg';
import home6 from '../res/img/homePage/6.jpg';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		textAlign: 'center',
		height: '90vh',
		overflow: 'hidden',
	},
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		// color: "black",
		backgroundColor: 'black',
		opacity: '50%',
		height: '100%',
		width: '100%',
	},
	text: {
		position: 'absolute',
		top: '40%',
		left: '50%',
		color: 'white',
		transform: 'translate(-50%, -50%)',
		'& > *': {
			margin: theme.spacing(4),
		},
	},
	actionButton: {
		padding: '16px',
		'&:hover': {
			color: 'white',
		},
	},
	carouselImage: {
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}));

export default function LandingPageCarousel() {
	const classes = useStyles();
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
	};

	const carouselItems = [
		{
			image: home1,
			title: 'Litter Free India',
			description: 'Run to make India Litter free by 2030. Volunteer with us and make a positive impact',
			actionName: 'Volunteer',
			actionHref: '/get-involved/volunteer',
		},
		{
			image: home2,
			title: 'Run for India',
			description: 'Run to make India Litter free by 2030. Volunteer with us and make a positive impact',
			actionName: 'Volunteer',
			actionHref: '/get-involved/volunteer',
		},
		{
			image: home3,
			title: 'Pledge for a clean India',
			description: 'Bring your children in fun-filled community activities, which teach responsible citizenship and are a lot of fun',
			actionName: 'Volunteer',
			actionHref: '/get-involved/volunteer',
		},
		{
			image: home4,
			title: 'Plastic Upvaas',
			description: "Reduce your plastic consumption and reuse or recycle your old plastic this year. Sign this pledge to save our planet's flore, fauna and natural aethetics",
			actionName: 'Sign this Petition',
			actionHref:
				'https://www.change.org/p/united-nations-environment-program-what-s-the-one-single-use-item-you-will-shun-let-s-start-with-saying-no-to-surgical-mask?utm_source=share_petition&utm_medium=custom_url&recruited_by_id=38771800-ae82-11e7-b359-05d137cfe7f8&use_react=false',
		},
		{
			image: home5,
			title: 'Trash Workout',
			description: 'Participate with us in uniting your internal health with your external health. Workout to train your muscles and trash cleanup to keep your lungs and heart healthy!',
			actionName: 'Volunteer',
			actionHref: '/get-involved/volunteer',
		},
		{
			image: home6,
			title: 'Join the community',
			description: "Bring your friends and family together and participate in making your surroundings clean while exercising. A complete healthcare solution for today's urban life.",
			actionName: 'Volunteer',
			actionHref: '/get-involved/volunteer',
		},
	];
	return (
		<Carousel {...settings}>
			{carouselItems.map((cItem) => (
				<div className={classes.container} key={cItem.actionName}>
					<img src={cItem.image} alt={cItem.description} className={classes.carouselImage} />
					<div className={classes.overlay}>&nbsp;</div>
					<div className={classes.text}>
						<Typography variant='h2'>{cItem.title}</Typography>
						<Typography variant='h5'>{cItem.description}</Typography>
						<Button variant='contained' size='large' href={cItem.actionHref} color='secondary' className={classes.actionButton}>
							{cItem.actionName}
						</Button>
					</div>
				</div>
			))}
		</Carousel>
	);
}
