import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import styles from '../../res/jss/material-kit-react/components/headerLinksStyle.js';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import NavbarAdHome from './NavbarAdHome';
import logo from './../../res/img/logo-transparent.png';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	logo: {
		padding: theme.spacing(1),
		maxWidth: '110px',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '80px',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: 'secondary',
	},
	title: {
		flexGrow: 1,
	},
	list: {
		width: 200,
	},
	padding: {
		paddingRight: 30,
		cursor: 'pointer',
	},
	sideBarIcon: {
		padding: 0,
		color: 'white',
		cursor: 'pointer',
	},
	disableHover: {
		'&:hover': {
			color: 'white',
		},
		fontColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	container: {
		position: 'relative',
	},
	rootSocial: {
		flexGrow: 1,
		width: '80px',
		textAlign: 'center',
		justifyContent: 'center',
		position: 'absolute',
		border: '1px solid black',
		top: '0px',
		left: '0px',
	},
	socialIcons: {
		color: 'black',
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverButton: {
		padding: theme.spacing(2),
	},
}));

const useDrawerStyle = makeStyles((theme) => ({
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		textAlign: 'center',
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

function ElevationScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 10 : 0,
	});
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

function Navbar(props) {
	const classes = useStyles();
	const drawerClasses = useDrawerStyle();
	const [opens, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openMenu, setOpenMenu] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const handleRequestClose = () => {
		setOpenMenu(false);
		setAnchorEl(null);
	};

	const navOptions = [
		['About Us', '/aboutus'],
		['Events', '/events'],
		['In News', '/news'],
		['Contact Us', '/contact-us'],
	];

	const nestedNavOptns = [
		['Overview', '/what-we-do'],
		['Plastic Waste', '/activities/plasticwaste'],
		['Litter free India', 'href'],
		['Ploggers Of India', 'href'],
		['Trash Work Out', 'href'],
		['Ride For Change', 'href'],
		['FIT India Plogging Runs', 'href'],
	];

	const loggedIn = localStorage.getItem('token') ? true : false;

	return (
		<div>
			<div className={classes.container}>
				<ElevationScroll {...props}>
					{loggedIn ? (
						<NavbarAdHome />
					) : (
						<AppBar className={classes.appbar} color='secondary'>
							<Toolbar>
								<Hidden mdUp>
									<IconButton edge='start' color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.hide)}>
										<MenuIcon />
									</IconButton>
								</Hidden>

								<div className={classes.title}>
									<Button href='/' color='inherit' className={classes.disableHover} disableRipple disableFocusRipple>
										<img src={logo} alt='Logo' className={classes.logo} />
									</Button>
								</div>
								<Hidden smDown>
									{navOptions.map((option) => (
										<Button key={option[0]} href={option[1]} color='inherit' size='large' className={classes.disableHover}>
											{option[0]}
										</Button>
									))}
									<Button
										// aria-owns={openMenu ? "simple-menu" : null}
										// aria-haspopup="true"
										// onClick={handleClick}
										// onMouseOver={handleClick}
										// onMouseLeave={handlePopoverClose}
										className={classes.disableHover}
										href='/what-we-do'
										color='inherit'
										size='large'
									>
										What we do?
									</Button>

									<Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup='true' onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
										{/* Hover with a Popover. */}
										<Button
											// aria-owns={openMenu ? "simple-menu" : null}
											// aria-haspopup="true"
											// onClick={handleClick}
											// onMouseOver={handleClick}
											// onMouseLeave={handlePopoverClose}
											className={classes.disableHover}
											href='/what-we-do'
											color='inherit'
											size='large'
										>
											What we do?
										</Button>
									</Typography>
									<Popover
										id='mouse-over-popover'
										className={classes.popover}
										classes={{
											paper: classes.paper,
										}}
										open={open}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
										onClose={handlePopoverClose}
										disableRestoreFocus
										interactive
									>
										{nestedNavOptns.map((option) => (
											<div display='block' key={option[0]}>
												<Button key={option[0]} href={option[1]} variant='contained' color='secondary' fullWidth className={classes.popoverButton}>
													{option[0]}
												</Button>
											</div>
										))}
									</Popover>
								</Hidden>

								<Popover
									open={openMenu}
									anchorEl={anchorEl}
									onClose={handleRequestClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									{nestedNavOptns.map((option) => (
										<div display='block' key={option[0]}>
											<Button key={option[0]} href={option[1]} variant='contained' color='secondary' fullWidth className={classes.popoverButton}>
												{option[0]}
											</Button>
										</div>
									))}
								</Popover>
								<Button
									key='Volunteer'
									href={loggedIn ? '/profile' : '/get-involved/volunteer'}
									color='primary'
									variant='contained'
									// className={classes.disableHover}
								>
									{loggedIn ? 'Profile' : 'Get Involved'}
								</Button>
							</Toolbar>
						</AppBar>
					)}
				</ElevationScroll>
				<Toolbar />
			</div>

			<Drawer
				className={drawerClasses.drawer}
				variant='persistent'
				anchor='left'
				open={opens}
				onClose={handleDrawerClose}
				classes={{
					paper: drawerClasses.drawerPaper,
				}}
			>
				<div className={drawerClasses.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<List component='nav' aria-label='navigation pane'>
					{navOptions.map((option) => (
						<ListItem button component='a' href={option[1]} key={option[0]}>
							<ListItemText align='center' primary={option[0]} />
						</ListItem>
					))}
				</List>
				<List component='nav' aria-label='social media link tree'>
					<IconButton href='facebook.com' className={classes.socialIcons}>
						<FacebookIcon fontSize='large' />
					</IconButton>
					<IconButton href='facebook.com' className={classes.socialIcons}>
						<InstagramIcon fontSize='large' />
					</IconButton>
					<IconButton href='facebook.com' className={classes.socialIcons}>
						<TwitterIcon fontSize='large' />
					</IconButton>
					<IconButton href='facebook.com' className={classes.socialIcons}>
						<LinkedInIcon fontSize='large' />
					</IconButton>
					<IconButton href='facebook.com' className={classes.socialIcons}>
						<YouTubeIcon fontSize='large' />
					</IconButton>
				</List>
			</Drawer>
		</div>
	);
}

export default withStyles(styles)(Navbar);
