import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Container } from '@material-ui/core';
import images from './../res/img/sign.jpg';
import PageHeadingType from '../res/jss/PageHeadingType';

const styles = (theme) => ({
	heading: {
		position: 'absolute',
		color: 'white',
		top: (props) => (props.type === PageHeadingType.MAIN ? '50%' : props.type === PageHeadingType.SECTION ? '60%' : 0),
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	container: {
		position: 'relative',
		height: (props) => (props.type === PageHeadingType.MAIN ? '70vh' : props.type === PageHeadingType.SECTION ? '40vh' : 0),
	},
	horizontalUnderline: {
		color: 'white',
		width: '60px',
		height: '3px',
		backgroundColor: 'white',
		marginTop: theme.spacing(0),
		border: 'white',
	},
	description: {
		marginTop: theme.spacing(4),
	},
	subText: {
		marginTop: theme.spacing(2),
	},
});

class PageHeading extends Component {
	render() {
		const { classes, name, description, subText } = this.props;
		return (
			<div>
				<Container maxWidth='xl' className={classes.container} disableGutters>
					<img src={images} alt='Header Banner' width='100%' height='100%' />
					<Container fixed maxWidth='lg' className={classes.heading}>
						<Typography variant='h2' align='center'>
							{name}
						</Typography>
						<hr className={classes.horizontalUnderline}></hr>
						<Typography variant='h5' align='center' className={classes.description}>
							{description}
						</Typography>
						<Typography variant='subtitle2' align='center' className={classes.subText}>
							{subText}
						</Typography>
					</Container>
				</Container>
			</div>
		);
	}
}

PageHeading.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string,
	type: PropTypes.oneOf(Object.values(PageHeadingType)),
	description: PropTypes.string,
	subText: PropTypes.string,
};

PageHeading.defaultProps = {
	type: PageHeadingType.SECTION,
	description: '',
	subText: '',
};

export default withStyles(styles, { withTheme: true })(PageHeading);
