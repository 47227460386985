import React from 'react';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from './Snackbar';
import { Card, CardMedia, CardContent, Link, List, ListItem, ListItemIcon, ListItemText, Grid, Typography, Collapse, withStyles, Button, IconButton } from '@material-ui/core';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: 10,
		marginBottom: 10,
	},
	eventImage: {
		width: 'auto',
		paddingBottom: '56.25%',
		margin: theme.spacing(2),
	},
	registerButton: {
		marginTop: theme.spacing(4),
	},
});

class EventCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			knowMore: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
		};
	}

	toggleKnowMore = () => {
		this.setState({ knowMore: !this.state.knowMore });
	};

	render() {
		const { classes, data, editEvents, admin, documentId, update, approveState, approveEvent, deleteEvent } = this.props;

		return (
			<Card variant='outlined' className={classes.root}>
				{this.state.snackbarOpen ? <Snackbar open='true' message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} /> : null}
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<CardMedia image={data.imageURL} title={data.name} className={classes.eventImage} />
					</Grid>
					<Grid item xs={12} sm={8}>
						<CardContent>
							<Grid container>
								<Grid item xs={9}>
									<Typography variant='h4'>{data.name}</Typography>
								</Grid>

								<Grid item xs={1}>
									{approveState === true ? (
										<IconButton onClick={() => approveEvent(documentId, data.selectedVolunteerId)}>
											<CheckCircleIcon />
										</IconButton>
									) : null}
								</Grid>

								<Grid item xs={1}>
									{editEvents || admin ? (
										<IconButton onClick={() => update(documentId)}>
											<EditIcon />
										</IconButton>
									) : null}
								</Grid>

								<Grid item xs={1}>
									{admin ? (
										<IconButton onClick={() => deleteEvent()}>
											<DeleteIcon />
										</IconButton>
									) : null}
								</Grid>

								<List dense style={{ display: 'flex' }}>
									<Grid container>
										<Grid item xs={12} sm={6}>
											<ListItem>
												<ListItemIcon>
													<EventIcon />
												</ListItemIcon>
												<ListItemText primary={data.date} />
											</ListItem>
										</Grid>
										<Grid item xs={12} sm={6}>
											<ListItem>
												<ListItemIcon>
													<ScheduleIcon />
												</ListItemIcon>
												<ListItemText primary={data.time} />
											</ListItem>
										</Grid>
										<Grid item xs={12} sm={6}>
											<ListItem>
												<ListItemIcon>
													<LocationOnIcon />
												</ListItemIcon>
												<ListItemText primary={data.city} />
											</ListItem>
										</Grid>
									</Grid>
								</List>
								<Grid item xs={12}>
									{data.extraInfo !== '' ? (
										<>
											<Link size='small' onClick={this.toggleKnowMore}>
												Know more...
											</Link>
											<Collapse in={this.state.knowMore}>
												<Typography variant='body2'>{data.extraInfo}</Typography>
											</Collapse>
										</>
									) : null}
								</Grid>
								<Grid item xs={12}>
									<Button variant='contained' color='secondary' fullWidth className={classes.registerButton} style={{ float: 'right' }}>
										Register
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Grid>
				</Grid>
			</Card>
		);
	}
}

export default withStyles(styles)(EventCard);
