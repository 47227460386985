import React, { Component } from 'react';
import { Link, Snackbar, Typography, TextField, Container, withStyles, IconButton, Modal, Fade, Backdrop, Grid, Button, CssBaseline } from '@material-ui/core';
import Navbar from '../../../Views/Navbar/Navbar';
import ClearIcon from '@material-ui/icons/Clear';
import { firebase } from '../../../Firebase';
import MuiAlert from '@material-ui/lab/Alert';
import Footer from '../../../Views/Footer';
import PageHeading from '../../../Views/PageHeading';
import ReCAPTCHA from 'react-google-recaptcha';

// IMAGES
import sdg from '../../../res/img/SDG.png';

const useStyles = (theme) => ({
	root: {
		padding: theme.spacing(4),
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '600px',
	},
	inputField: {
		width: '100%',
		marginTop: '10px',
	},
	submitButton: {
		marginTop: '15px',
	},
	closeModalButton: {
		float: 'right',
	},
	dividerContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '-10px',
	},
	border: {
		borderBottom: '1px solid lightgray',
		width: '100%',
	},
	content: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	heading: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
});

const createHistory = require('history').createBrowserHistory;

class Volunteer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nameOfVolunteer: '',
			areasOfVolunteer: '',
			phoneNumberOfVolunteer: '',
			emailOfVolunteer: '',
			modal: false,
			email: '',
			password: '',
			snackbarOpen: false,
			captchState: false,
		};

		this.addVolunteerModalOpen = this.addVolunteerModalOpen.bind(this);
		this.onVolunteerAdd = this.onVolunteerAdd.bind(this);
		this.login = this.login.bind(this);
		this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
		this.verifyCallback = this.verifyCallback.bind(this);
	}

	addVolunteerModalOpen() {
		if (this.state.modal) {
			this.setState({
				modal: false,
				nameOfVolunteer: '',
				areasOfVolunteer: '',
				phoneNumberOfVolunteer: '',
				emailOfVolunteer: '',
			});
		} else {
			this.setState({
				modal: true,
			});
		}
	}

	componentDidMount() {
		if (this.captchaDemo) {
			console.log('started, just a second...');
			this.captchaDemo.reset();
		}
	}

	onLoadRecaptcha() {
		if (this.captchaDemo) {
			this.captchaDemo.reset();
		}
	}

	verifyCallback(recaptchaToken) {
		this.setState({ captchState: true });
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	onVolunteerAdd(e) {
		e.preventDefault();

		const { nameOfVolunteer, areasOfVolunteer, phoneNumberOfVolunteer, emailOfVolunteer } = this.state;

		firebase.firestore().collection('volunteers').add({
			nameOfVolunteer: nameOfVolunteer,
			rankofVolunteer: 0,
			areasOfVolunteer: areasOfVolunteer,
			phoneNumberOfVolunteer: phoneNumberOfVolunteer,
			emailOfVolunteer: emailOfVolunteer,
			isVolunteerApproved: false,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});

		this.addVolunteerModalOpen();
		alert('Form Submitted');
	}

	login(e) {
		e.preventDefault();
		firebase
			.auth()
			.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then((u) => {
				return u.user.getIdToken();
			})
			.then((token) => {
				localStorage.setItem('token', token);
				let history = createHistory();
				history.push('/');
				let pathUrl = window.location.href;
				window.location.href = pathUrl;

				this.handleSnackbarClose();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleSnackbarClose() {
		if (this.state.snackbarOpen) {
			this.setState({
				snackbarOpen: false,
			});
		} else {
			this.setState({
				snackbarOpen: true,
			});
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<CssBaseline />
				<Navbar />
				<PageHeading name='Volunteer' />
				<Container maxWidth='md' className={classes.root}>
					<Grid container spacing={10}>
						<Grid item xs={12} sm={6}>
							<Typography variant='body1' gutterBottom>
								<img src={sdg} width='100%' alt='SDGs' />
							</Typography>

							<Typography variant='body2' gutterBottom>
								I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. So when you get something that has the
								name Kanye West on it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company that ends up being worth billions of dollars, because I
								got the answers. I understand culture. I am the nucleus.
							</Typography>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Typography variant='h6' align='center'>
								Login
							</Typography>
							<TextField
								id='outlined-basic'
								type='email'
								name='email'
								label='Email'
								variant='outlined'
								onChange={this.onChange}
								value={this.state.email}
								className={classes.inputField}
								required
							/>
							<TextField
								id='outlined-basic'
								type='password'
								name='password'
								label='Password'
								variant='outlined'
								onChange={this.onChange}
								value={this.state.password}
								className={classes.inputField}
								required
							/>
							<Button variant='contained' color='primary' onClick={this.login} fullWidth className={classes.submitButton}>
								Login
							</Button>
							<Typography gutterBottom></Typography>
							<Typography variant='subtitle2'>
								<Link href='/forget-password' color='textPrimary'>
									Forgot your password?
								</Link>
							</Typography>
							<div className={classes.dividerContainer}>
								<div className={classes.border} />
								<span className={classes.content}>or</span>
								<div className={classes.border} />
							</div>
							<Button variant='contained' color='primary' onClick={this.addVolunteerModalOpen} fullWidth size='large'>
								Become a Volunteer
							</Button>
						</Grid>
					</Grid>

					{this.state.modal ? (
						<Modal
							aria-labelledby='transition-modal-title'
							aria-describedby='transition-modal-description'
							className={classes.modal}
							open={this.state.modal}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
						>
							<Fade in={true}>
								<div className={classes.paper}>
									<IconButton onClick={this.addVolunteerModalOpen} className={classes.closeModalButton}>
										<ClearIcon />
									</IconButton>
									<form noValidate autoComplete='on'>
										<TextField
											id='outlined-basic'
											label='Name'
											variant='outlined'
											name='nameOfVolunteer'
											className={classes.inputField}
											value={this.state.nameOfVolunteer}
											onChange={this.onChange}
										/>
										<TextField
											id='outlined-basic'
											label='City'
											variant='outlined'
											name='areasOfVolunteer'
											className={classes.inputField}
											value={this.state.areasOfVolunteer}
											onChange={this.onChange}
										/>
										<TextField
											id='outlined-basic'
											label='Phone Number'
											variant='outlined'
											name='phoneNumberOfVolunteer'
											className={classes.inputField}
											value={this.state.phoneNumberOfVolunteer}
											onChange={this.onChange}
										/>{' '}
										<TextField
											id='outlined-basic'
											label='Email ID'
											variant='outlined'
											name='emailOfVolunteer'
											className={classes.inputField}
											value={this.state.emailOfVolunteer}
											onChange={this.onChange}
										/>
										<ReCAPTCHA size='large' onloadCallback={this.onLoadRecaptcha} onChange={this.verifyCallback} sitekey='6Lej8z8aAAAAALfZEH4-0Dhj8J4fdkob-95hxBAK' />
										{this.state.captchState ? (
											<Button variant='contained' color='secondary' onClick={this.onVolunteerAdd} className={classes.submitButton} fullWidth>
												Submit
											</Button>
										) : (
											<Button variant='contained' color='secondary' onClick={this.onVolunteerAdd} className={classes.submitButton} fullWidth disabled>
												Submit
											</Button>
										)}
									</form>
								</div>
							</Fade>
						</Modal>
					) : null}

					{this.state.snackbarOpen ? (
						<Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
							<MuiAlert elevation={6} variant='filled' onClose={this.handleSnackbarClose} severity='success'>
								Logged in Successfully
							</MuiAlert>
						</Snackbar>
					) : null}
				</Container>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withStyles(useStyles, { withTheme: true })(Volunteer);
