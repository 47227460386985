import React from 'react';
import { Card, withStyles, Button, Grid, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { red } from '@material-ui/core/colors';
import Snackbar from './Snackbar';
import { firebase } from './../Firebase';

const styles = (theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	avatar: {
		backgroundColor: red[500],
	},
	readArticleButton: {
		flexGrow: 1,
	},
});

class BlogCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			snackbarOpen: false,
		};
	}

	deleteBlog(id) {
		firebase
			.firestore()
			.collection('media')
			.doc(id)
			.delete()
			.then(() => {
				console.log('Document successfully deleted!');
			})
			.catch((error) => {
				console.error('Error removing document: ', error);
			});
	}

	render() {
		const { classes, date, image, description, link, headline, admin, documentId, update } = this.props;
		return (
			<Card className={classes.root}>
				{this.state.snackbarOpen ? <Snackbar open='true' message='Link to the Blog, is copied to clipboard' severity='success' /> : null}
				<CardHeader
					avatar={
						<Avatar aria-label='recipe' className={classes.avatar}>
							R
						</Avatar>
					}
					title='Ripu Daman Bevli'
					subheader={date}
				/>

				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<CardMedia className={classes.media} image={image} title='Blog Image' />
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography variant='h6' color='textSecondary' component='p'>
								{headline}
							</Typography>
							<Typography variant='body2' color='textSecondary' component='p'>
								{description}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions disableSpacing>
					<IconButton
						onClick={() => {
							navigator.clipboard.writeText(link);
							this.setState({
								snackbarOpen: true,
							});
							const blogCardComponent = this;
							setTimeout(function () {
								blogCardComponent.setState({
									snackbarOpen: false,
								});
							}, 3000);
						}}
						aria-label='add to favorites'
					>
						<FileCopyIcon />
					</IconButton>
					<Button className={classes.readArticleButton} href={link} variant='contained' color='primary'>
						Read Full Blog
					</Button>
				</CardActions>
				{admin ? (
					<CardActions disableSpacing>
						<Button onClick={() => update(documentId)} className={classes.readArticleButton} variant='contained' color='primary'>
							Update
						</Button>
						<Button onClick={() => this.deleteBlog(documentId)} className={classes.readArticleButton} variant='contained' color='primary'>
							Delete
						</Button>
					</CardActions>
				) : null}
			</Card>
		);
	}
}
export default withStyles(styles, { withTheme: true })(BlogCard);
