import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import WhatWeDo from './Pages/WhatWeDo';
import ContactUs from './Pages/ContactUs';
import AboutUs from './Pages/AboutUs';
import News from './Pages/News';
import Volunteer from './Pages/GetInvolved/Volunteer/Volunteer';
import Events from './Pages/Events';
import Blog from './Pages/Blog';
import PlasticWaste from './Pages/PlasticWaste';
import theme from './Views/Themes';
import { ThemeProvider } from '@material-ui/core/styles';
import ForgotPasswords from './Pages/ForgotPassword';

// Admin
import EventsManage from './Pages/AfterLogin/EventsManage';
import VolunteerManage from './Pages/AfterLogin/VolunteersManage';
import PermissionsManage from './Pages/AfterLogin/PermissionsManage';
import MediaManage from './Pages/AfterLogin/MediaManage';

// User
import Profile from './Views/Profile';

import { responsiveFontSizes } from '@material-ui/core/styles';

const themes = responsiveFontSizes(theme);

function App() {
	var hist = createBrowserHistory();
	return (
		<ThemeProvider theme={themes}>
			<Router history={hist}>
				<Switch>
					<Route path='/admin/events-manage' component={EventsManage} />
					<Route path='/admin/volunteer-manage' component={VolunteerManage} />
					<Route path='/admin/permission-manage' component={PermissionsManage} />
					<Route path='/admin/media-manage' component={MediaManage} />
					<Route path='/profile' component={Profile} />
					<Route path='/forget-password' component={ForgotPasswords} />
					<Route path='/activities/plasticwaste' component={PlasticWaste} />
					<Route path='/get-involved/volunteer' component={Volunteer} />
					<Route path='/contact-us' component={ContactUs} />
					<Route path='/events' component={Events} />
					<Route path='/what-we-do' component={WhatWeDo} />
					<Route path='/aboutus' component={AboutUs} />
					<Route path='/blogs' component={Blog} />
					<Route path='/news' component={News} />
					<Route path='/' component={Home} />
				</Switch>
			</Router>
		</ThemeProvider>
	);
}

export default App;
