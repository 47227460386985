import React from 'react';
import { firebase } from '../Firebase';
import { withStyles, CssBaseline, Container } from '@material-ui/core';
import Footer from '../Views/Footer';
import PageHeading from '../Views/PageHeading';
import NewsCard from '../Components/NewsCard';
import Loader from '../Components/Loader';
import Navbar from '../Views/Navbar/Navbar';

const styles = (theme) => ({
	gridOutline: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		margin: theme.spacing(1),
		width: '100%',
	},
	newsDisplayImage: {
		padding: theme.spacing(1),
		height: '100%',
		width: '100%',
	},
	newsChip: {
		padding: theme.spacing(1),
	},
	heading: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
});

class News extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			news: [],
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection('media')
			.orderBy('timestamp', 'desc')
			.onSnapshot((querySnapshot) => {
				var news = [];
				querySnapshot.forEach((doc) => {
					if (doc.data().typeOfMedia === 'news') {
						news.push(doc.data());
					}
				});
				this.setState({
					news: news,
					isLoading: false,
				});
			});
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Navbar />

				<div className={classes.root}>
					<React.Fragment>
						<CssBaseline />
						<PageHeading name='News' />
						<Container maxWidth='md'>
							{this.state.isLoading ? (
								<Loader />
							) : (
								<>
									{this.state.news.length !== 0
										? this.state.news.map((data) => (
												<NewsCard
													admin='false'
													date={data.date}
													image={data.imageURL}
													description={data.description}
													link={data.linkToArticle}
													headline={data.headline}
													readingTime={data.readingTime}
													source={data.sourceOfNews}
												/>
										  ))
										: null}
								</>
							)}
						</Container>
						<Footer />
					</React.Fragment>
				</div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(News);
