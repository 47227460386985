import React from 'react';
import { Tooltip, Card, List, ListItem, IconButton, ListItemIcon, ListItemText, CardContent, Grid, Avatar, Typography, withStyles, Button } from '@material-ui/core';
import Filter1Icon from '@material-ui/icons/Filter1';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import StarsIcon from '@material-ui/icons/Stars';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	volunteerImage: {
		border: '2px solid',
		width: theme.spacing(22),
		height: theme.spacing(22),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
});

class VolunteerCard extends React.Component {
	render() {
		const { classes, data, editVolunteers, admin, update, documentId, approve, approveVolunteer, deleteVolunteer } = this.props;

		return (
			<Grid item xs={12}>
				<Card variant='outlined' className={classes.root}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={4}>
							<Avatar alt={data.name} src={data.imageURL} className={classes.volunteerImage} />
						</Grid>
						<Grid item xs={12} sm={8}>
							<CardContent>
								<Grid container>
									<Grid item xs={9}>
										<Typography variant='h4'>{data.nameOfVolunteer}</Typography>
									</Grid>

									<Grid item xs={1}>
										{approve === true ? (
											<IconButton onClick={() => approveVolunteer(documentId, data.emailOfVolunteer)}>
												<CheckCircleIcon />
											</IconButton>
										) : null}
									</Grid>

									<Grid item xs={1}>
										{editVolunteers ? (
											<IconButton onClick={() => update(documentId)}>
												<EditIcon />
											</IconButton>
										) : null}
									</Grid>

									<Grid item xs={1}>
										{admin ? (
											<IconButton onClick={() => deleteVolunteer()}>
												<DeleteIcon />
											</IconButton>
										) : null}
									</Grid>

									<List dense style={{ display: 'flex' }}>
										<Grid container>
											<Grid item xs={12} sm={6}>
												<Tooltip title='Email of Volunteer' arrow={true}>
													<ListItem>
														<ListItemIcon>
															<EmailIcon />
														</ListItemIcon>
														<ListItemText primary={data.emailOfVolunteer} />
													</ListItem>
												</Tooltip>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Tooltip title='Area of Volunteer' arrow={true}>
													<ListItem>
														<ListItemIcon>
															<LocationOnIcon />
														</ListItemIcon>
														<ListItemText primary={data.areaOfVolunteer} />
													</ListItem>
												</Tooltip>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Tooltip title='Phone Number of Volunteer' arrow={true}>
													<ListItem>
														<ListItemIcon>
															<PhoneIcon />
														</ListItemIcon>
														<ListItemText primary={data.phoneNumberOfVolunteer} />
													</ListItem>
												</Tooltip>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Tooltip title='Number of Events Organized by Volunteer' arrow={true}>
													<ListItem>
														<ListItemIcon>
															<EventAvailableIcon />
														</ListItemIcon>
														<ListItemText primary={data.numberOfEventsOrganized} />
													</ListItem>
												</Tooltip>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Tooltip title='Rank of Volunteer' arrow={true}>
													<ListItem>
														<ListItemIcon>
															<StarsIcon />
														</ListItemIcon>
														<ListItemText primary={data.rankofVolunteer} />
													</ListItem>
												</Tooltip>
											</Grid>
										</Grid>
									</List>
								</Grid>
							</CardContent>
						</Grid>
					</Grid>
				</Card>
			</Grid>
		);
	}
}

export default withStyles(styles)(VolunteerCard);
