import React, { Component } from 'react';
import { CircularProgress, Typography, Grid, TextField, Avatar, CssBaseline, Container, withStyles, Button } from '@material-ui/core';
import Navbar from './Navbar/Navbar';
import { firebase } from './../Firebase';
import Loader from './../Components/Loader';
import Footer from './Footer';
import Snackbar from './../Components/Snackbar';

const styles = (theme) => ({
	profileImage: {
		width: '200px',
		height: '200px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	inputField: {
		width: '100%',
		marginBottom: theme.spacing(1),
	},
	root: {
		position: 'relative',
		textAlign: 'center',
		marginBottom: theme.spacing(8),
	},
	appbar: theme.mixins.toolbar,
	profileImageHolder: {
		textAlign: 'center',
		marginBottom: theme.spacing(4),
	},
});

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogin: false,
			nameOfVolunteer: '',
			emailOfVolunteer: '',
			areaOfVolunteer: '',
			numberOfEventsOrganizedByVolunteer: '',
			phoneNumberOfVolunteer: '',
			rankOfVolunteer: '',
			image: '',
			progress_image: 0,
			imageURL: '',
			isLoading: true,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
		};
	}

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		const profileComponent = this;

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				profileComponent.setState(
					{
						emailOfVolunteer: user.email,
						isLogin: true,
					},
					function () {
						firebase
							.firestore()
							.collection('volunteers')
							.where('emailOfVolunteer', '==', profileComponent.state.emailOfVolunteer)
							.onSnapshot((querySnapshot) => {
								const profileInfo = [];
								const profileId = [];

								querySnapshot.forEach(function (doc) {
									profileInfo.push(doc.data());
									profileId.push(doc.id);
								});

								profileComponent.setState({
									nameOfVolunteer: profileInfo[0].nameOfVolunteer,
									emailOfVolunteer: profileInfo[0].emailOfVolunteer,
									areaOfVolunteer: profileInfo[0].areaOfVolunteer,
									numberOfEventsOrganizedByVolunteer: profileInfo[0].numberOfEventsOrganized,
									phoneNumberOfVolunteer: profileInfo[0].phoneNumberOfVolunteer,
									rankOfVolunteer: profileInfo[0].rankofVolunteer,
									imageURL: profileInfo[0].imageURL,
									isLoading: false,
									id: profileId[0],
								});
							});
					}
				);
			}
		});
	}

	onImageChange = async (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			this.setState({
				image: file.name,
			});

			const timeStamp = Date.now();
			const profileComponent = this;

			var uploadTask = firebase
				.storage()
				.ref()
				.child('profile/' + timeStamp + file.name)
				.put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				function (snapshot) {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState({ progress_image: progress });

					if (progress === 100) {
						var url;

						setTimeout(() => {
							firebase
								.storage()
								.ref()
								.child('profile/' + timeStamp + file.name)
								.getDownloadURL()
								.then(function (downloadURL) {
									console.log('File available at', downloadURL);
									url = downloadURL;
									profileComponent.setState({
										imageURL: url,
									});

									profileComponent.renderSnackbar('Image Uploaded Successfully!', 'success');

									setTimeout(function () {
										profileComponent.setState({
											progress_image: 0,
										});
									}, 1000);
								});
						}, 3000);
					}

					console.log('Upload is ' + progress + '% done');
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log('Upload is paused');
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log('Upload is running');
							break;
						default:
							break;
					}
				}.bind(this),
				function (error) {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;
						default:
							break;
					}
				}
			);
		} else {
			this.setState(() => ({ image: 'image' }));
		}
	};

	onProfileUpdate = (e) => {
		e.preventDefault();

		const profileComponent = this;

		const { nameOfVolunteer, areaOfVolunteer, phoneNumberOfVolunteer, id, imageURL } = this.state;

		firebase
			.firestore()
			.collection('volunteers')
			.doc(id)
			.update({
				nameOfVolunteer: nameOfVolunteer,
				areaOfVolunteer: areaOfVolunteer === undefined ? '' : areaOfVolunteer,
				phoneNumberOfVolunteer: phoneNumberOfVolunteer === undefined ? '' : phoneNumberOfVolunteer,
				imageURL: imageURL === undefined ? '' : imageURL,
			})
			.then(function () {
				profileComponent.renderSnackbar('Profile Updated Successfully!', 'success');
			})
			.catch(function (error) {
				console.error('Error updating document: ', error);
				profileComponent.renderSnackbar('Profile Updated Error!', 'error');
			});
	};

	render() {
		const { classes } = this.props;

		const textFields = [
			['Name', 'nameOfVolunteer', this.state.nameOfVolunteer, false, 'text'],
			['Email', 'emailOfVolunteer', this.state.emailOfVolunteer, true, 'email'],
			['Area', 'areaOfVolunteer', this.state.areaOfVolunteer, false, 'text'],
			['Number Of Events Organized', 'numberOfEventsOrganizedByVolunteer', this.state.numberOfEventsOrganizedByVolunteer, true, 'number'],
			['Phone number', 'phoneNumberOfVolunteer', this.state.phoneNumberOfVolunteer, false, 'number'],
			['Rank', 'rankOfVolunteer', this.state.rankOfVolunteer, true, 'number'],
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<Navbar />
				<Typography gutterBottom></Typography>
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{!this.state.isLogin ? (
							<Typography gutterBottom align='center'>
								Login to view your profile
							</Typography>
						) : (
							<Container maxWidth='md'>
								<Typography variant='h2' align='center' gutterBottom>
									Your Profile
								</Typography>
								<Grid container className={classes.root} direction='row' justify='space-evenly' alignItems='flex-start' spacing={2}>
									<Grid item xs={12} sm={5}>
										<div className={classes.profileImageHolder} style={{ position: 'relative' }}>
											<span style={{ position: 'absolute' }}>
												<Avatar className={classes.profileImage} src={this.state.imageURL} />
											</span>
											<CircularProgress variant='determinate' value={this.state.progress_image} size={200} thickness={2.3} />
										</div>

										<input accept='image/*' className={classes.input} id='contained-button-file' multiple type='file' onChange={this.onImageChange} style={{ display: 'none' }} />
										<label htmlFor='contained-button-file'>
											<Button variant='outlined' color='secondary' size='large' component='span'>
												Update Photo?
											</Button>
										</label>
									</Grid>
									<Grid item xs={12} sm={7}>
										{textFields.map((handle) => (
											<TextField
												id='outlined-basic'
												variant='outlined'
												className={classes.inputField}
												type={handle[4]}
												label={handle[0]}
												name={handle[1]}
												value={handle[2]}
												onChange={this.onChange}
												disabled={handle[3]}
											/>
										))}
										<Button variant='outlined' color='secondary' size='large' onClick={this.onProfileUpdate}>
											Update Profile?
										</Button>
									</Grid>
								</Grid>
							</Container>
						)}
					</>
				)}
				<Footer />
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Profile);
