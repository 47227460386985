import image from "../../res/img/bg.jpg";
import image1 from "../../res/img/landing.jpg";
import image2 from "../../res/img/bg2.jpg";
import image3 from "../../res/img/bg3.jpg";
import image4 from "../../res/img/bg4.jpg";
import image5 from "../../res/img/bg7.jpg";

const tileData = [
    {
        img: image,
        title: "Image",
        author: "author",
    },
    {
        img: image1,
        title: "Image",
        author: "author",
    },
    {
        img: image2,
        title: "Image",
        author: "author",
    },
    {
        img: image3,
        title: "Image",
        author: "author",
    },
    {
        img: image4,
        title: "Image",
        author: "author",
    },
    {
        img: image5,
        title: "Image",
        author: "author",
    },
];

export default tileData;
