import React from 'react';
import Navbar from '../Views/Navbar/Navbar';
import { GridListTileBar, GridListTile, GridList, withStyles, Button, CssBaseline, Container, Typography } from '@material-ui/core';
import LandingPageCarousel from '../Views/LandingPageCarousel';
import Footer from './../Views/Footer';
import tileData from './News/tileData';
import InitiativeShoutView from '../Views/Home/InitiativeShoutView';

import dummyImg from '../res/img/4-by-3-1024x768.png';

const styles = (theme) => ({
	carouselHome: {
		width: '100%',
	},
	rootSocial: {
		flexGrow: 1,
		width: '80px',
		textAlign: 'center',
		justifyContent: 'center',
		position: 'absolute',
		// border: "1px solid black",
		top: 0,
		left: 0,
		zIndex: 2,
	},
	socialIcons: {
		transform: 'scale(2)',
	},
	appBarSpacer: theme.mixins.toolbar,
	title: {
		color: theme.palette.primary.light,
	},
	titleBar: {
		background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	gridStart: {
		marginTop: theme.spacing(16),
		marginBottom: theme.spacing(16),
	},
	gridList: {
		flexWrap: 'nowrap',
		transform: 'translateZ(0)',
	},
	newsHeadline: {
		marginTop: theme.spacing(4),
	},
	newsButton: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
		'&:hover': {
			color: 'white',
		},
	},
});

class Home extends React.Component {
	render() {
		const { classes } = this.props;

		const homeData = [
			[dummyImg, 'Plastic Upvaas', 'row', 'button-link', 'Know More'],
			[dummyImg, 'Trash Workout', 'row-reverse', 'button-link', 'Know More'],
			[dummyImg, 'Ploggers of India', 'row', 'button-link', 'Know More'],
		];

		return (
			<React.Fragment>
				<CssBaseline />
				<Navbar />
				<LandingPageCarousel className={classes.carouselHome} />

				<Container maxWidth='lg'>
					{homeData.map((data, index) => (
						<InitiativeShoutView data={data} key={index} />
					))}
				</Container>

				<Container className={classes.gridStart} maxWidth='md'>
					<Typography variant='h3' align='center' gutterBottom>
						In the News
					</Typography>
					<GridList className={classes.gridList} cols={2.5}>
						{tileData.map((tile) => (
							<GridListTile key={tile.img}>
								<img src={tile.img} alt={tile.title} height='100%' />
								<GridListTileBar
									title={tile.title}
									classes={{
										root: classes.titleBar,
										title: classes.title,
									}}
								/>
							</GridListTile>
						))}
					</GridList>
					<Typography className={classes.newsButton} gutterBottom align='center'>
						<Button variant='contained' href='/news' color='primary'>
							Ploggers in News
						</Button>
					</Typography>
				</Container>

				<Footer />
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Home);
