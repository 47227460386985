import React from 'react';
import clsx from 'clsx';
import { Typography, makeStyles, Grid, CssBaseline, Button, Container } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import Footer from './../Views/Footer';
import PageHeading from './../Views/PageHeading';

import sdg from '../res/img/SDG.png';

const useStyles = makeStyles((theme) => ({
	bgDiff: {
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(8),
	},
	horizontalUnderline: {
		color: '#000',
		width: '60px',
		height: '3px',
		backgroundColor: '#000',
		marginTop: '-10px',
		margingBottom: theme.spacing(2),
		border: '#000',
	},
	dataDisplay: {
		marginTop: theme.spacing(5),
	},
	pageRedirectButton: {
		marginTop: theme.spacing(4),
	},
	heading: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
	headingText: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	headingImage: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		width: '100%',
	},
}));

const data = [
	[
		'Plastic Waste',
		'Plastic and packaging is omni-present. So how do you shun plastic from your life? Plastic Upvaas pledge gives you a direction so that you can identify one thing that you could easily shun from your life. One item to start with, that is single use. In the Covid times, we are asking you to shun surgical masks and instead, choose reusable cloth masks….',
		'https://www.youtube.com/embed/Pqq2kAANMSw',
		'Plastic Waste',
		'activities/plasticwaste',
		'row-reverse',
		true,
	],
	[
		'Litter free India',
		'The Litter Free India combines fitness and running to fight the garbage crisis in the country. Our participants clean up a place and then take the Pledge For Swachh Bharat which includes pledge to not litter, reducing and segregating waste as their contribution towards a cleaner neighbourhood.',
		'https://www.youtube.com/embed/pqqh_xbbltw',
		'Litter free India',
		'activities/plasticwaste',
		'row',
		false,
	],
	[
		'Ploggers Of India',
		'From the initial My City, My Responsibility, we created the Plogging movement in the country by running and citizen groups involved in cleanups in several cities and towns across the country. Today, we have communities working in so many cities including Mumbai, Bangalore, Puducherry, Chandigarh, and even smaller towns like Kota, Beed etc.',
		'https://www.youtube.com/embed/o4qgT8yoMMU',
		'Ploggers Of India',
		'href',
		'row-reverse',
		true,
	],
];

const noVideoData = [
	[
		'Trash Work Out',
		'It’s our flagship school program where we make picking up litter for students and kids a holistic full body workout. For some videos, go to our YT channel',
		'',
		'Trash Work Out',
		'href',
		'row',
		false,
	],
	[
		'Ride For Change',
		'Upcoming Campaign- A zero emission zero waste ride over 6000 kilometers creating a socio-environmental impact and giving people hope that we will come out of this pandemic stronger.',
		'',
		'Ride For Change',
		'href',
		'row-reverse',
		true,
	],
	[
		'FIT India Plogging Runs',
		'It’s our flagship school program where we make picking up litter for students and kids a holistic full body workout. For some videos, go to our YT channel',
		'',
		'FIT India Plogging Runs',
		'href',
		'row-reverse',
		false,
	],
];

export default function ButtonAppBar() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Navbar />
			<React.Fragment>
				<CssBaseline />
				<PageHeading name='What we do' img={sdg} />
				<Container fixed maxWidth='lg'>
					<Typography variant='body2' className={classes.headingText} gutterBottom align='justify'>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
						took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining
						essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
						like Aldus PageMaker including versions of Lorem Ipsum
					</Typography>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<img src={sdg} className={classes.headingImage} alt='Sdg' />
						</Grid>
					</Grid>
				</Container>

				<Container maxWidth='xl' disableGutters>
					{data.map((data) => (
						<Container key={data[0]} maxWidth='lg' className={clsx(classes.container, data[6] ? classes.bgDiff : null)}>
							<Typography variant='h3' align='center' gutterBottom>
								{data[0]}
							</Typography>
							<hr className={classes.horizontalUnderline}></hr>
							<Grid container spacing={4} direction={data[5]} className={classes.dataDisplay}>
								<Grid item xs={12} sm={6}>
									<Typography variant='body2' align='justify' gutterBottom>
										{data[1]}
									</Typography>
									<Typography align='center'>
										<Button variant='contained' color='primary' size='large' href={data[4]} className={classes.pageRedirectButton}>
											{data[3]}
										</Button>
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<iframe
										title={data[0]}
										width='100%'
										height='300px'
										src={data[2]}
										frameBorder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									></iframe>
								</Grid>
							</Grid>
						</Container>
					))}
				</Container>

				<Container maxWidth='xl' disableGutters>
					{noVideoData.map((data) => (
						<Container key={data[0]} maxWidth='lg' className={clsx(classes.container, data[6] ? classes.bgDiff : null)}>
							<Typography variant='h3' align='center' gutterBottom>
								{data[0]}
							</Typography>
							<hr className={classes.horizontalUnderline}></hr>
							<Grid container spacing={4} direction={data[5]} className={classes.dataDisplay}>
								<Grid item xs={12}>
									<Typography variant='body2' align='justify' gutterBottom>
										{data[1]}
									</Typography>
									<Typography align='center'>
										<Button variant='contained' color='primary' size='large' className={classes.pageRedirectButton}>
											Trash Work Out
										</Button>
									</Typography>
								</Grid>
							</Grid>
						</Container>
					))}
				</Container>
			</React.Fragment>
			<Footer />
		</div>
	);
}
