import { withStyles } from '@material-ui/core';
import React from 'react';
import { Typography, Button, Grid } from "@material-ui/core";

const styles = (theme) => ({
	headStart: {
		marginTop: theme.spacing(6),
	},
	headImage: {
		width: "100%",
		height: "100%",
	},
	headerText: {
		flex: 1,
		justifyContent: "center",
		alignSelf: "center",
		marginLeft: theme.spacing(16),
		marginRight: theme.spacing(16),
	},
})

class InitiativeShoutView extends React.Component {
	render() {
		const { classes, data } =  this.props;

		return (
			<Grid
				container
				spacing={4}
				direction={data[2]}
				className={classes.headStart}
			>
				<Grid item xs={12} sm={4}>
					<img src={data[0]} className={classes.headImage} alt={data[1]}></img>
				</Grid>
				<Grid item xs={12} sm={8} className={classes.headerText}>
					<Typography gutterBottom variant="h3" display="block">
						{data[1]}
					</Typography>
					<Button variant="contained" color="secondary" href={data[3]}>
						{data[4]}
					</Button>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styles)(InitiativeShoutView);