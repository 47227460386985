import React, { Component } from 'react';
import PageHeading from '../Views/PageHeading';
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles, Button, Grid, CssBaseline, Container, TextField } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import ContactsIcon from '@material-ui/icons/Contacts';
import Footer from '../Views/Footer';

const createHistory = require('history').createBrowserHistory;

const styles = (theme) => ({
	inputField: {
		width: '100%',
		textAlign: 'center',
		marginBottom: '10px',
	},
	mainText: {
		fontWeight: 'bolder',
	},
	subHeading: {
		fontWeight: 'bold',
	},
	container: {
		padding: theme.spacing(4),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(8),
			marginBottom: theme.spacing(8),
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
});

let isFormSubmittable = false;

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			subject: '',
			message: '',
		};

		this.submitForm.bind(this);
	}

	submitForm(e) {
		e.preventDefault();
		if (!isFormSubmittable) {
			isFormSubmittable = true;
		} else {
			let history = createHistory();
			history.push('/');
			let pathUrl = window.location.href;
			window.location.href = pathUrl;
		}
	}

	render() {
		const { classes } = this.props;

		const formFields = [
			['Name', 'text', 'entry.2005620554', 0],
			['Email', 'email', 'entry.1045781291', 0],
			['Subject', 'text', 'entry.1065046570', 0],
			['Message', 'text', 'entry.1166974658', 4],
		];
		return (
			<div className={classes.root}>
				<Navbar />
				<React.Fragment>
					<CssBaseline />
					<PageHeading name='Contact Us' />
					<Container maxWidth='md' className={classes.container}>
						<Grid container spacing={10}>
							<Grid item xs={12} sm={6}>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<Typography gutterBottom align='left' variant='body1' className={classes.mainText}>
											We'd love to hear from you!
											<br />
											Send us an email at <Link href='mailto:contact@world-action.org'>contact@world-action.org</Link> or using the form.
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography gutterBottom align='left' variant='subtitle2' className={classes.subHeading}>
											Postal Address
										</Typography>
										<Typography gutterBottom align='left' variant='body2' className={classes.subBodyText}>
											Dummy Address Line 1, Dummy Address Line 2, Locality name, New Delhi, India - 110PIN
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography gutterBottom align='left' variant='subtitle2' className={classes.subHeading}>
											Working Office
										</Typography>
										<Typography gutterBottom align='left' variant='body2' className={classes.subBodyText}>
											Dummy Address Line 1, Dummy Address Line 2, Locality name, New Delhi, India - 110PIN
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<List dense>
											<ListItem>
												<ListItemIcon>
													<ContactsIcon />
												</ListItemIcon>
												<ListItemText primary='+91-98PHONENo1' />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<ContactsIcon />
												</ListItemIcon>
												<ListItemText primary='+91-98PHONENo2' />
											</ListItem>
										</List>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6}>
								<form
									className={classes.root}
									noValidate
									autoComplete='on'
									target='hidden_iframe'
									action='https://docs.google.com/forms/d/e/1FAIpQLSdpJ1tZ5roy15SFAXsvy5IaWO6Uy4oLOUtLBhbYg3Z2W5LRDw/formResponse'
								>
									{formFields.map((data) => (
										<TextField
											id='outlined-basic'
											key={data[0]}
											label={data[0]}
											type={data[1]}
											name={data[2]}
											rows={data[3]}
											multiline
											variant='outlined'
											className={classes.inputField}
										/>
									))}
									<Button variant='contained' type='submit' color='primary' fullWidth>
										Submit
									</Button>
								</form>
								<iframe name='hidden_iframe' id='hidden_iframe' hidden title='Contact Us Form' onLoad={this.submitForm} />
							</Grid>
						</Grid>
					</Container>
					<Footer />
				</React.Fragment>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Form);
