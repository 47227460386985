import React from 'react';
import { Container, IconButton, Hidden, List, ListItemText, ListItem, makeStyles, AppBar, Grid, CssBaseline, Typography } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import logo from './../res/img/logo-transparent.png';

const useStyles = makeStyles((theme) => ({
	appBar: {
		marginTop: theme.spacing(2),
		color: theme.palette.primary.main,
		flex: 1,
		justifyContent: 'center',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4),
			paddingTop: theme.spacing(8),
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(8),
		},
	},
	socialHandles: {
		transform: 'scale(1.5)',
		color: theme.palette.primary.main,
	},
	logo: {
		padding: 'auto',
		width: '100%',
		maxWidth: '200px',
		marginBottom: theme.spacing(2),
	},
}));

const initiatives = [['Plastic Waste'], ['Litter free India'], ['Ploggers Of India'], ['Trash Work Out'], ['Ride For Change'], ['FIT India Plogging Runs']];

const legal = [['Terms & Conditions'], ['Privacy Policy']];

const socialHandles = [
	['Facebook', <FacebookIcon />, 'https://fb.com'],
	['Twitter', <TwitterIcon />, 'https://twitter.com'],
	['Instagram', <InstagramIcon />, 'https://instagram.com'],
	['YouTube', <YouTubeIcon />, 'https://youtube.com'],
];

export default function BottomAppBar() {
	const classes = useStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position='static' color='secondary' className={classes.appBar}>
				<Container maxWidth='md'>
					<Grid container spacing={5}>
						<Grid item xs={12} sm={4} md={3}>
							<Typography variant='h5' align='left'>
								Initiatives
							</Typography>
							<List dense component='nav' aria-label='main mailbox folders'>
								{initiatives.map((data, index) => (
									<ListItem button key={index}>
										<ListItemText primary={data[0]} />
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<Typography align='left' variant='body1'>
								Legal
							</Typography>
							{legal.map((data, index) => (
								<ListItem dense button key={index}>
									<ListItemText primary={data[0]} />
								</ListItem>
							))}
						</Grid>
						<Hidden smDown>
							<Grid item md={3}></Grid>
						</Hidden>
						<Grid item xs={12} sm={4} md={3}>
							<img src={logo} alt='Logo' className={classes.logo} />
							<Typography variant='body2' gutterBottom>
								© 2021 World Action
							</Typography>

							<Grid container spacing={0}>
								{socialHandles.map((handle) => (
									<Grid item xs={3} key={handle[0]}>
										<IconButton href={handle[2]} className={classes.socialHandles}>
											{handle[1]}
										</IconButton>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</AppBar>
		</React.Fragment>
	);
}
