import firebase from "firebase";

const config = {
	apiKey: "AIzaSyAWPePkZw5R35j_kH-eWuuAZ3JPIlAfGMk",
	authDomain: "ploggers-of-india-website.firebaseapp.com",
	databaseURL: "https://ploggers-of-india-website.firebaseio.com",
	projectId: "ploggers-of-india-website",
	storageBucket: "ploggers-of-india-website.appspot.com",
	messagingSenderId: "994429982478",
	appId: "1:994429982478:web:38025633c7d400a765a95f",
	measurementId: "G-Q605BHPMYN",
};

firebase.initializeApp(config);
firebase.analytics();

export { firebase };
