import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#ef595c",
        },
        secondary: {
            main: "#ffffff",
        },
        background: {
            default: '#cfd1d0',
            paper: '#f3f4f6',
        },
    },
    typography: {
        body1: {
            fontSize: "1.4rem",
        },
        body2: {
            fontSize: "1rem",
        },
        h4: {
            fontWeight: "bolder",
        },
        h2: {
            fontWeight: "bolder",
        },
        h3: {
            fontWeight: "bold",
        },
    },
});

export default theme;
