import React from 'react';
import { Container, CssBaseline, withStyles } from '@material-ui/core';
import Navbar from '../Views/Navbar/Navbar';
import { firebase } from '../Firebase';
import BlogCard from '../Components/BlogCard';
import Loader from '../Components/Loader';
import PageHeading from '../Views/PageHeading';
import Footer from '../Views/Footer';

const styles = (theme) => ({
	loader: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing(16),
		width: theme.spacing(8),
	},
	container: {
		minHeight: '60vh',
	},
});

class Blog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			blogs: [],
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection('media')
			.where('typeOfMedia', '==', 'blog')
			.onSnapshot((querySnapshot) => {
				var blogs = [];
				querySnapshot.forEach((doc) => {
					blogs.push(doc.data());
				});
				this.setState({
					blogs: blogs,
					isLoading: false,
				});
			});
	}

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Navbar />
				<CssBaseline />
				<PageHeading name='Blogs' />
				<Container maxWidth='md' className={classes.container}>
					{this.state.isLoading ? (
						<Loader />
					) : (
						<>
							{this.state.blogs.length !== 0
								? this.state.blogs.map((data) => <BlogCard date={data.date} image={data.imageURL} description={data.description} link={data.linkToArticle} headline={data.headline} />)
								: null}
						</>
					)}
				</Container>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Blog);
