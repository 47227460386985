import React from 'react';
import { Container, RootRef, Card, withStyles, Chip, Button, Grid, CardMedia, CardContent, CardActions, IconButton, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { red } from '@material-ui/core/colors';
import Snackbar from './Snackbar';
import { firebase } from './../Firebase';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';

const styles = (theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	avatar: {
		backgroundColor: red[500],
	},
	readArticleButton: {
		flexGrow: 1,
	},
});

class NewsCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			snackbarOpen: false,
		};
	}

	deleteNews(id) {
		firebase
			.firestore()
			.collection('media')
			.doc(id)
			.delete()
			.then(() => {
				console.log('Document successfully deleted!');
			})
			.catch((error) => {
				console.error('Error removing document: ', error);
			});
	}

	render() {
		const { classes, date, image, link, headline, readingTime, source, admin, documentId, update, id, index, provided } = this.props;
		return (
			// <Container maxWidth="md">
			// 	{admin === "false" ? (
			// 		<Card className={classes.root}>
			// 			{this.state.snackbarOpen ? <Snackbar open="true" message="Link to the News, is copied to clipboard" severity="success" /> : null}

			// 			<CardContent>
			// 				<Grid container spacing={3}>
			// 					<Grid item xs={12} sm={4}>
			// 						<CardMedia className={classes.media} image={image} title="Blog Image" />
			// 					</Grid>
			// 					<Grid item xs={12} sm={6}>
			// 						<Chip label="Ploggers in News" className={classes.newsChip} gutterBottom />
			// 						<Typography variant="body2" gutterBottom>
			// 							{date} . {readingTime}
			// 						</Typography>
			// 						<Typography variant="h4" component="h1" gutterBottom>
			// 							{headline}
			// 						</Typography>
			// 						<Typography variant="body2" gutterBottom>
			// 							Source: {source}
			// 						</Typography>
			// 					</Grid>
			// 				</Grid>
			// 			</CardContent>
			// 			<CardActions disableSpacing>
			// 				<IconButton
			// 					onClick={() => {
			// 						navigator.clipboard.writeText(link);
			// 						this.setState({
			// 							snackbarOpen: true,
			// 						});
			// 						const newsCardComponent = this;
			// 						setTimeout(function () {
			// 							newsCardComponent.setState({
			// 								snackbarOpen: false,
			// 							});
			// 						}, 3000);
			// 					}}
			// 					aria-label="add to favorites"
			// 				>
			// 					<FileCopyIcon />
			// 				</IconButton>
			// 				<Button className={classes.readArticleButton} href={link} variant="contained" color="primary">
			// 					Read Full News
			// 				</Button>
			// 			</CardActions>
			// 		</Card>
			// 	) : (

			// <RootRef rootRef={provided.innerRef} {...provided.droppableProps}>
			<div>
				<Draggable key={JSON.stringify(id)} draggableId={JSON.stringify(id)} index={index}>
					{(provided) => (
						<Card className={classes.root} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
							{this.state.snackbarOpen ? <Snackbar open='true' message='Link to the News, is copied to clipboard' severity='success' /> : null}
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={4}>
										<CardMedia className={classes.media} image={image} title='Blog Image' />
									</Grid>
									<Grid item xs={12} sm={6}>
										<Chip label='Ploggers in News' className={classes.newsChip} gutterBottom />
										<Typography variant='body2' gutterBottom>
											{date} . {readingTime}
										</Typography>
										<Typography variant='h4' component='h1' gutterBottom>
											{headline}
										</Typography>
										<Typography variant='body2' gutterBottom>
											Source: {source}
										</Typography>
									</Grid>
									<Grid item xs={1}>
										{admin ? (
											<IconButton onClick={() => update(documentId)}>
												<UpdateIcon />
											</IconButton>
										) : null}
									</Grid>

									<Grid item xs={1}>
										{admin ? (
											<IconButton onClick={() => this.deleteNews(documentId)}>
												<DeleteIcon />
											</IconButton>
										) : null}
									</Grid>
								</Grid>
							</CardContent>
							{/* <CardActions disableSpacing>
											<IconButton
												onClick={() => {
													navigator.clipboard.writeText(link);
													this.setState({
														snackbarOpen: true,
													});
													const newsCardComponent = this;
													setTimeout(function () {
														newsCardComponent.setState({
															snackbarOpen: false,
														});
													}, 3000);
												}}
												aria-label="add to favorites"
											>
												<FileCopyIcon />
											</IconButton>
											<Button className={classes.readArticleButton} href={link} variant="contained" color="primary">
												Read Full News
											</Button>
										</CardActions> */}
						</Card>
					)}
				</Draggable>
				{provided.placeholder}
			</div>
			// </RootRef>

			// 	)}
			// </Container>
		);
	}
}
export default withStyles(styles, { withTheme: true })(NewsCard);
